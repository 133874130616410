import { postInfo } from './api';

export default {
  // 续期仓管费配置列表
  getRenewalFeeList(data) {
    return postInfo('/renewalFee/getRenewalFeeList', data);
  },
  // 添加续期仓管费配置
  addRenewalFee(data) {
    return postInfo('/renewalFee/addRenewalFee', data);
  },
  // 删除续期仓管费配置
  deleteRenewalFeeById(data) {
    return postInfo('/renewalFee/deleteRenewalFeeById', data);
  },
  // 删除续期仓管费配置
  deleteRenewalFee(data) {
    return postInfo('/renewalFee/deleteRenewalFee', data);
  },
  // 编辑续期仓管费配置
  modifyRenewalFee(data) {
    return postInfo('/renewalFee/modifyRenewalFee', data);
  },
  // 估价计算器
  renewalFeeCalc(data) {
    return postInfo('/renewalFee/renewalFeeCalc', data);
  },
};
