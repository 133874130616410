<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="寄存期满待赎回受理"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
    @close="close"
    @closed="closed"
  >
    <div class="submit-form">
      <dynamic-order-info
        :blocks="blocks"
        v-loading="loading"
      ></dynamic-order-info>
      <el-form
        ref="form"
        size="small"
        label-width="170px"
        :model="model"
        :rules="rules"
      >
        <div class="form-block">
          <p class="form-block-title">赎回受理</p>

          <el-row>
            <el-col :span="10">
              <el-form-item label="客户是否赎回货品" prop="isRedeem">
                <el-switch
                  v-model="model.isRedeem"
                  :active-value="1"
                  :inactive-value="0"
                  @change="value => change('isRedeem', value)"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="model.isRedeem === 1">
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="爱押分润比例"
                  prop="shareBenefitRate"
                >
                  <el-select
                    v-model="model.shareBenefitRate"
                    placeholder="爱押分润比例"
                    @change="value => change('shareBenefitRate', value)"
                  >
                    <el-option
                      v-for="(option, optionIndex) in this.filterDictionary(
                        '0130',
                      )"
                      :key="`shareBenefitRate-${optionIndex}`"
                      :label="option.name"
                      :value="option.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="爱押分润金额"
                  prop="shareBenefitAmount"
                >
                  <el-input
                    v-model="model.shareBenefitAmount"
                    readonly
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="合作商分润金额"
                  prop="partnerShareBenefitAmount"
                >
                  <el-input
                    v-model="model.partnerShareBenefitAmount"
                    readonly
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="请选择实际赎回日期" prop="realRedeemDate">
                  <el-date-picker
                    v-model="model.realRedeemDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="请上传与客户远程沟通确认赎回截图"
                  prop="attachment-15"
                >
                  <el-input v-show="false" v-model="model['attachment-15']">
                  </el-input>
                  <l-upload-img
                    v-if="visible"
                    :fileUrl="mixinInitAttachment[15]"
                    :folderName="'order'"
                    @getFile="files => _updateFilefield(15, files)"
                  >
                  </l-upload-img>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="请上传与客户支付货品赎回款截图"
                  prop="attachment-16"
                >
                  <el-input v-show="false" v-model="model['attachment-16']">
                  </el-input>
                  <l-upload-img
                    v-if="visible"
                    :fileUrl="mixinInitAttachment[16]"
                    :folderName="'order'"
                    @getFile="files => _updateFilefield(16, files)"
                  >
                  </l-upload-img>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="赎回款支付时间"
                  prop="redeemMoneyPaymentDate"
                >
                  <el-date-picker
                    v-model="model.redeemMoneyPaymentDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="赎回款收款处理人" prop="actorUserName">
                  <el-input
                    v-model="model.actorUserName"
                    placeholder="请搜索处理人姓名"
                    readonly
                  >
                    <el-button
                      type="primary"
                      slot="append"
                      icon="el-icon-search"
                      @click="
                        select('select-sys-name', {
                          roleName: '',
                          type: 'actorUserId',
                        })
                      "
                    >
                    </el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="赎回款收款账号属性"
                  prop="accountAttribute"
                >
                  <el-select
                    v-model="model.accountAttribute"
                    placeholder="请选择赎回款收款账号属性"
                    @change="value => change('accountAttribute', value)"
                  >
                    <el-option
                      v-for="(option, optionIndex) in this.filterDictionary(
                        '0117',
                      )"
                      :key="`accountAttribute-${optionIndex}`"
                      :label="option.name"
                      :value="option.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="赎回款收款账号种类" prop="accountType">
                  <el-select
                    v-model="model.accountType"
                    placeholder="请选择赎回款收款账号种类"
                  >
                    <el-option
                      v-for="(option, optionIndex) in this.filterDictionary(
                        '0118',
                      )"
                      :key="`accountType-${optionIndex}`"
                      :label="option.name"
                      :value="option.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="赎回支付账单流水号"
                  prop="paymentSerialNumber"
                >
                  <el-input
                    v-model="model.paymentSerialNumber"
                    placeholder="请输入"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="公司收款账号" prop="creditedAccount">
                  <el-input
                    v-model="model.creditedAccount"
                    placeholder="请搜索公司收款账号"
                    readonly
                  >
                    <el-button
                      type="primary"
                      slot="append"
                      icon="el-icon-search"
                      @click="
                        select('select-account', {
                          type: 'creditedAccount',
                          accountAttribute: model.accountAttribute,
                          accountType: model.accountType,
                          accountUse: 2,
                        })
                      "
                    >
                    </el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="客户支付账号" prop="paymentAccount">
                  <el-input
                    v-model="model.paymentAccount"
                    placeholder="请输入客户收款账号"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div v-if="model.isRedeem === 0">
            <el-row>
              <el-col :span="10">
                <el-form-item label="客户选择非赎回方式" prop="noRedeemWay">
                  <el-select
                    v-model="model.noRedeemWay"
                    placeholder="请选择客户选择非赎回方式"
                    @change="value => change('noRedeemWay', value)"
                  >
                    <el-option
                      v-for="(option, optionIndex) in this.filterDictionary(
                        '0119',
                      )"
                      :key="`noRedeemWay-${optionIndex}`"
                      :label="option.name"
                      :value="option.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-if="model.noRedeemWay === '1'">
              <el-row>
                <el-col :span="10">
                  <el-form-item label="确认续期天数" prop="renewalDays">
                    <el-input-number
                      v-model="model.renewalDays"
                      :min="1"
                      :max="100"
                      placeholder="请输入"
                      @change="value => change('renewalDays', value)"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10">
                  <el-form-item label="续期费用" prop="renewalFee">
                    <el-input
                      v-model="model.renewalFee"
                      v-loading="computeLoading"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit(0)">
        保存
      </el-button>
      <el-button type="warning" :loading="submitLoading" @click="submit(1)">
        提交
      </el-button>
    </div>
    <select-sys-name
      ref="select-sys-name"
      @selected="selectedName"
    ></select-sys-name>
    <select-account
      ref="select-account"
      @selected="selectedName"
    ></select-account>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import SelectSysName from '../../../components/SelectSysName.vue';
import api from '../../../api/OrderManagement';
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';
import { mapState } from 'vuex';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';
import getInfoMixin from '../../../mixins/get-info-mixin';
import SelectAccount from '../../../components/SelectAccount.vue';
import renewalFeeApi from '../../../api/RenewalFeeApi';

export default {
  name: 'WaitRedeemAcceptance',
  components: {
    LUploadImg,
    SelectSysName,
    DynamicOrderInfo,
    SelectAccount,
  },
  props: {},
  mixins: [getInfoMixin, updateFilefieldMixin],
  data() {
    return {
      loading: false,
      visible: false,
      id: '',
      info: null,
      model: {
        isRedeem: 1,
        realRedeemDate: '',
        'attachment-15': '',
        'attachment-16': '',
        redeemMoneyPaymentDate: '',
        actorUserId: '',
        actorUserName: '',
        accountAttribute: '',
        accountType: '',
        paymentSerialNumber: '',
        creditedAccount: '',
        paymentAccount: '',

        noRedeemWay: '',
        renewalDays: '',
        renewalFee: '',

        goodsAmount: '',
        consignDays: '',
        consignFee: '',
        redeemAmount: '',
        shareBenefitRate: '',
        shareBenefitAmount: '',
        partnerShareBenefitAmount: '',
      },
      rules: {
        isRedeem: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        realRedeemDate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-15': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-16': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        redeemMoneyPaymentDate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        actorUserName: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        accountAttribute: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        accountType: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        paymentSerialNumber: [
          {
            required: true,
            message: '请输入',
          },
        ],
        creditedAccount: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        paymentAccount: [
          {
            required: true,
            message: '请输入',
          },
        ],
        noRedeemWay: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        renewalDays: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        shareBenefitRate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        shareBenefitAmount: [
          {
            required: true,
            message: '待计算',
          },
        ],
        partnerShareBenefitAmount: [
          {
            required: true,
            message: '待计算',
          },
        ],
      },
      mapOfAttachment: {},
      submitLoading: false,
      computeLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    blocks() {
      const orderAssessmentInfo = this.info?.orderAssessmentInfo;
      const result = [];
      if (orderAssessmentInfo) {
        const items = [
          {
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productInfo,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productSerialNumber,
              },
            ],
          },
        ];
        result.push(...items);
      }
      const orderExpireRedeemInfo = this.info?.orderExpireRedeemInfo;
      if (orderExpireRedeemInfo) {
        const items = [
          {
            title: '赎回信息',
            list: [
              {
                lable: '货款支付金额',
                type: 'amount',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.goodsAmount,
                danger: true,
              },
              {
                lable: '寄存天数',
                type: 'text',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.consignDays,
              },
              {
                lable: '寄存费用',
                type: 'amount',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.consignFee,
                danger: true,
              },
              {
                lable: '赎回需支付总金额',
                type: 'amount',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.redeemAmount,
                danger: true,
              },
            ],
          },
        ];
        result.push(...items);
      }
      return result;
    },
  },
  created() {},
  methods: {
    open(id) {
      this.id = id;
      this.loading = true;
      this.visible = true;
      this._getInfo().then(() => {
        const target = { ...this.model };
        Object.assign(target, {
          goodsAmount: this.info?.orderExpireRedeemInfo?.orderRedeemInfo
            ?.goodsAmount,
          consignDays: this.info?.orderExpireRedeemInfo?.orderRedeemInfo
            ?.consignDays,
          consignFee: this.info?.orderExpireRedeemInfo?.orderRedeemInfo
            ?.consignFee,
          redeemAmount: this.info?.orderExpireRedeemInfo?.orderRedeemInfo
            ?.redeemAmount,
        });
        this.model = target;
      });

      this._initSaveInfo('orderRedeemAccept');
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.id = '';
      this.loading = false;
      this.submitLoading = false;
      this.info = null;
      this.mapOfAttachment = {};
    },
    select(ref, context) {
      if (ref) {
        if (ref === 'select-account') {
          const { accountAttribute, accountType } = this.model;
          if (!accountAttribute) {
            this.$message.warning('请先选择赎回款收款账号属性');
            return false;
          }
          if (!accountType) {
            this.$message.warning('请先选择赎回款收款账号种类');
            return false;
          }
        }
        this.$refs[ref].open(context);
      }
      return true;
    },
    submit(isSubmit) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi(isSubmit);
        }
      });
    },
    selectedName(data, { type }) {
      const target = { ...this.model };
      switch (type) {
        case 'actorUserId':
          {
            const { id, userName } = data;
            Object.assign(target, {
              actorUserId: id,
              actorUserName: userName,
            });
          }
          break;
        case 'creditedAccount':
          {
            const { accountNumber } = data;
            Object.assign(target, {
              creditedAccount: accountNumber,
            });
          }
          break;
        default:
          break;
      }

      this.model = target;
    },
    requestApi(isSubmit = 0) {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, model, mapOfAttachment } = this;

        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          isSubmit,
          ...model,
          orderId: id,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
        };
        api
          .finishConfirmRedeem(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
    change(type, value) {
      switch (type) {
        case 'accountAttribute':
        case 'accountType':
          {
            const target = { ...this.model };
            Object.assign(target, {
              creditedAccount: '',
            });
            this.model = target;
          }
          break;
        case 'isRedeem':
          {
            this.$refs.form.resetFields();
            const target = { ...this.model };
            Object.assign(target, {
              isRedeem: value,
            });
            this.model = target;
            this.mapOfAttachment = {};
            this.mixinInitAttachment = {};
          }
          break;
        case 'noRedeemWay':
          {
            const target = { ...this.model };
            Object.assign(target, {
              renewalDays: '',
              renewalFee: '',
            });
            this.model = target;
            if (value === '1') {
              this.$nextTick(() => {
                this.compute();
              });
            }
          }
          break;
        case 'renewalDays':
          if (value) {
            this.compute();
          }
          break;
        case 'shareBenefitRate':
          this.model.shareBenefitAmount = Math.ceil((this.model.consignFee * this.model.shareBenefitRate) / 100);
          this.model.partnerShareBenefitAmount = this.model.consignFee - this.model.shareBenefitAmount;
          break;
        default:
          break;
      }
    },
    compute() {
      const { noRedeemWay, renewalDays } = this.model;
      if (noRedeemWay !== '1' || !renewalDays) {
        return false;
      }

      this.computeLoading = true;
      const promise = new Promise((resolve, reject) => {
        const ext = {
          assessmentAmount: this.info?.orderExpireRedeemInfo?.orderRedeemInfo
            ?.goodsAmount,
          renewalDays,
        };
        renewalFeeApi
          .renewalFeeCalc(ext)
          .then(res => {
            const target = { ...this.model };
            Object.assign(target, {
              renewalFee: res.result,
            });
            this.model = target;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.computeLoading = false;
          });
      });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped></style>
