import api from '../api/OrderManagement';
import oss from '../utils/oss';

const client = oss.client();

const mixin = {
  data() {
    return {
      mixinInitAttachment: {},
    };
  },
  created() {},
  methods: {
    _getInfo() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { id } = this;
        api
          .getOrderInfo(id)
          .then(res => {
            const { result } = res;
            this._formatData(result);
            this.info = result;
            resolve(result);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    _formatData(target) {
      if (target !== null && typeof target === 'object') {
        if (target.attachmentInfoList) {
          this._generateValidUrl(target.attachmentInfoList);
        } else {
          Object.values(target).forEach(point => this._formatData(point));
        }
      }
    },
    _generateValidUrl(list) {
      if (list && Array.isArray(list)) {
        list.forEach(point => {
          const { attachmentPath } = point;
          if (attachmentPath) {
            point.url = client.signatureUrl(attachmentPath);
          }
        });
      }
    },
    _initSaveInfo(name) {
      const promise = new Promise((resolve, reject) => {
        this._getSaveInfo()
          .then(result => {
            const { attachmentInfo, sysUserInfo } = result;
            const keys = Object.keys(this.model);
            const model = {};
            const mapOfAttachment = {};
            const mixinInitAttachment = {};
            keys.forEach(key => {
              let value;
              if (key.startsWith('attachment-')) {
                const type = Number.parseInt(key.replace(/[^\d]/g, ''), 10);
                const items = attachmentInfo.filter(
                  ({ attachmentType }) => attachmentType === type,
                );
                if (items.length) {
                  value = items.map(({ attachmentPath }) => attachmentPath);
                  value = `${value}`;
                  mapOfAttachment[type] = items;
                  mixinInitAttachment[type] = value;
                }
              } else if (key.endsWith('Id') && keys.includes(key.replace('Id', 'Name'))) {
                const id = result?.[name]?.[key];
                value = id;

                const target = sysUserInfo.find(item => `${item.id}` === `${id}`);
                const userName = target?.userName;
                if (userName !== undefined) {
                  model[key.replace('Id', 'Name')] = userName;
                }
              } else {
                value = result?.[name]?.[key];
              }
              if (value !== undefined) {
                model[key] = value;
              }
            });
            Object.assign(this.model, model);
            this.$nextTick(() => {
              this.$refs.form.clearValidate();
            });

            Object.assign(this.mapOfAttachment, mapOfAttachment);
            Object.assign(this.mixinInitAttachment, mixinInitAttachment);
            resolve({
              model,
              mapOfAttachment,
            });
          })
          .catch(e => {
            reject(e);
          });
      });
      return promise;
    },
    _getSaveInfo() {
      const promise = new Promise((resolve, reject) => {
        const { id } = this;
        api
          .getOrderSaveInfo(id)
          .then(res => {
            const { result } = res;
            this._formatData(result);
            resolve(result);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {});
      });
      return promise;
    },
  },
};

export default mixin;
