import { postInfo } from './api';

export default {
  // 分页查询公司账户
  getCompanyAccountPageList(data) {
    return postInfo('/companyAccount/getCompanyAccountPageList', data);
  },
  // 添加公司账户
  addCompanyAccount(data) {
    return postInfo('/companyAccount/addCompanyAccount', data);
  },
  // 删除公司账户
  deleteCompanyAccount(data) {
    return postInfo('/companyAccount/deleteCompanyAccount', data);
  },
  // 修改公司账户
  modifyCompanyAccount(data) {
    return postInfo('/companyAccount/modifyCompanyAccount', data);
  },
  getAccountLedgerPageList(data) {
    return postInfo('/companyAccount/getAccountLedgerPageList', data);
  },
  // 入账
  recorded(data) {
    return postInfo('/companyAccount/recorded', data);
  },
};
