<template>
  <dynamic-order-info :title="title" :blocks="blocks"></dynamic-order-info>
</template>
<script>
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';

export default {
  name: 'InfoRedeem',
  components: {
    DynamicOrderInfo,
  },
  props: {
    info: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      title: '期满赎回订单详情',
    };
  },
  computed: {
    blocks() {
      const { info } = this;
      let result = [];
      if (info) {
        result = [
          {
            title: '赎回信息',
            renderer: !!info?.orderRedeemInfo,
            list: [
              {
                lable: '货款支付金额',
                type: 'amount',
                value: info?.orderRedeemInfo?.goodsAmount,
                danger: true,
              },
              {
                lable: '寄存天数',
                type: 'text',
                value: info?.orderRedeemInfo?.consignDays,
              },
              {
                lable: '寄存费用',
                type: 'amount',
                value: info?.orderRedeemInfo?.consignFee,
                danger: true,
              },
              {
                lable: '爱押分润比例',
                type: 'persent',
                value: info?.orderRedeemAcceptInfo?.shareBenefitRate,
              },
              {
                lable: '爱押分润金额',
                type: 'amount',
                value: info?.orderRedeemAcceptInfo?.shareBenefitAmount,
              },
              {
                lable: '合作商分润金额',
                type: 'amount',
                value: info?.orderRedeemAcceptInfo?.partnerShareBenefitAmount,
              },
              {
                lable: '赎回需支付总金额',
                type: 'amount',
                value: info?.orderRedeemInfo?.redeemAmount,
                danger: true,
              },
            ],
          },
          {
            title: '赎回受理信息',
            renderer: !!info?.orderRedeemAcceptInfo,
            list: [
              {
                lable: '实际赎回日期',
                type: 'text',
                value: info?.orderRedeemAcceptInfo?.realRedeemDate,
              },
              {
                lable: '与客户远程沟通确认赎回截图',
                type: 'images',
                attachmentList:
                  info?.orderRedeemAcceptInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 15,
                  ) ?? [],
              },
              {
                lable: '与客户支付货品赎回款截图',
                type: 'images',
                attachmentList:
                  info?.orderRedeemAcceptInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 16,
                  ) ?? [],
              },
              {
                lable: '赎回款支付时间',
                type: 'text',
                value: info?.orderRedeemAcceptInfo?.redeemMoneyPaymentDate,
              },
              {
                lable: '赎回款收款处理人',
                type: 'text',
                value: info?.orderRedeemAcceptInfo?.actorUserName,
              },
              {
                lable: '赎回账号种类',
                type: 'dictTranslate',
                value: info?.orderRedeemAcceptInfo?.creditedAccountType,
                dictCode: '0118',
              },
              {
                lable: '赎回支付账单流水号',
                type: 'text',
                value: info?.orderRedeemAcceptInfo?.paymentSerialNumber,
              },
              {
                lable: '公司收款账号',
                type: 'text',
                value: info?.orderRedeemAcceptInfo?.creditedAccount,
              },
              {
                lable: '客户支付账号',
                type: 'text',
                value: info?.orderRedeemAcceptInfo?.paymentAccount,
              },
            ],
          },
          {
            title: '赎回回寄信息',
            renderer: !!info?.orderRedeemReturnInfo,
            list: [
              {
                lable: '库存单号',
                type: 'text',
                value: info?.orderDeliverInfo?.storageCode,
              },
              {
                lable: '是否现场收货',
                type: 'dictTranslate',
                value: info?.orderRedeemReturnInfo?.isLive,
                dictCode: '0123',
              },
              {
                renderer: !info?.orderRedeemReturnInfo?.isLive,
                lable: '快递单号',
                type: 'text',
                value: info?.orderRedeemReturnInfo?.courierNumber,
              },
              {
                renderer: !info?.orderRedeemReturnInfo?.isLive,
                lable: '寄件时间',
                type: 'text',
                value: info?.orderRedeemReturnInfo?.courierSentDate,
              },
              {
                renderer: !info?.orderRedeemReturnInfo?.isLive,
                lable: '客户收件地址',
                type: 'text',
                value: info?.orderRedeemReturnInfo?.shippingAddress,
              },
              {
                renderer: !info?.orderRedeemReturnInfo?.isLive,
                lable: '快递单号照片上传',
                type: 'images',
                attachmentList:
                  info?.orderRedeemReturnInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 17,
                  ) ?? [],
              },
            ],
          },
          {
            title: '货品出库信息',
            renderer: !!info?.orderDeliverInfo,
            list: [
              {
                lable: '货品出库时间',
                type: 'text',
                value: info?.orderDeliverInfo?.deliverTime,
              },
              {
                lable: '出库操作人',
                type: 'text',
                value: info?.orderDeliverInfo?.deliverPersonName,
              },
              {
                lable: '库存单号',
                type: 'text',
                value: info?.orderDeliverInfo?.storageCode,
              },
            ],
          },
          {
            title: '回寄到货信息',
            renderer: !!info?.orderRedeemConfirmArrivalInfo,
            list: [
              {
                lable: '收件时间',
                type: 'text',
                value: info?.orderRedeemConfirmArrivalInfo?.receiptDate,
              },
              {
                lable: '客户收件签收照片上传',
                type: 'images',
                attachmentList:
                  info?.orderRedeemConfirmArrivalInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 18,
                  ) ?? [],
              },
              {
                lable: '远程与客户确认收货截图照片上传',
                type: 'images',
                attachmentList:
                  info?.orderRedeemConfirmArrivalInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 19,
                  ) ?? [],
              },
            ],
          },
        ];
      }

      return result;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss"></style>
