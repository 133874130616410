<template>
  <dynamic-order-info :title="title" :blocks="blocks"></dynamic-order-info>
</template>
<script>
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';

export default {
  name: 'InfoValuation',
  components: {
    DynamicOrderInfo,
  },
  props: {
    info: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      title: '检测估值订单详情',
    };
  },
  computed: {
    blocks() {
      const { info } = this;
      let result = [];
      if (info) {
        result = [
          {
            renderer: !!info?.baseOrderInfo,
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: info?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: info?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            renderer: !!info?.baseOrderInfo,
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: info?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: info?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: info?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            renderer: !!info?.baseOrderInfo,
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: info?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: info?.baseOrderInfo?.productInfo,
              },
              {
                lable: '基础估值',
                type: 'amount',
                value: info?.baseOrderInfo?.basicPrice,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: info?.baseOrderInfo?.productSerialNumber,
              },
              {
                lable: '初始估值',
                type: 'amount',
                value: info?.baseOrderInfo?.initAssessment,
              },
              {
                lable: '远程与客户报价',
                type: 'amount',
                value: info?.baseOrderInfo?.priceQuotation,
              },
            ],
          },
          {
            title: '检测记录',
            renderer: !!info?.orderDetectionInfo,
            list: [
              {
                lable: '系统最新估值',
                type: 'amount',
                value: info?.orderDetectionInfo?.assessmentPrice,
              },
              {
                lable: '检测师估值',
                type: 'amount',
                value: info?.orderDetectionInfo?.detectionAssessment,
              },
              {
                lable: '检测师描述检测过程及估值判断依据',
                type: 'text-table',
                text: info?.orderDetectionInfo?.detectionRemark,
                columns: [
                  {
                    type: 'index',
                    label: '序号',
                  },
                  {
                    label: '问题项',
                    prop: 'questionDescription',
                  },
                  {
                    label: '选项',
                    prop: 'optionDescription',
                  },
                  {
                    label: '估值影响',
                    prop: 'impactBasePrice',
                  },
                ],
                tableData: info?.orderDetectionInfo?.orderQuestionList ?? [],
              },
              {
                lable: '是否拆机',
                type: 'dictTranslate',
                value: info?.orderDetectionInfo?.isDismantle,
                dictCode: '0123',
              },
              {
                lable: '产品检测相关图片',
                type: 'images',
                attachmentList:
                  info?.orderDetectionInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 5,
                  ) ?? [],
              },
              {
                lable: '产品检测视频录像文件',
                type: 'videos',
                attachmentList:
                  info?.orderDetectionInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 6,
                  ) ?? [],
              },
            ],
          },
          {
            title: '估值确认记录',
            renderer:
              !!info?.orderConfirmAssessmentInfo &&
              info?.orderConfirmAssessmentInfo?.isAcceptAssessment === 1,
            list: [
              {
                lable: '确认估值金额',
                type: 'amount',
                value: info?.orderConfirmAssessmentInfo?.assessmentPrice,
              },
              {
                lable: '确认寄存天数',
                type: 'dictTranslate',
                value: info?.orderConfirmAssessmentInfo?.consignDays,
                dictCode: '0115',
              },
              {
                lable: '支付金额',
                type: 'amount',
                value: info?.orderConfirmAssessmentInfo?.paymentAmount,
                danger: true,
              },
              {
                lable: '系统寄存费',
                type: 'amount',
                value: info?.orderConfirmAssessmentInfo?.sysConsignFee,
                danger: true,
              },
              {
                lable: '折扣寄存费',
                type: 'amount',
                value: info?.orderConfirmAssessmentInfo?.discountConsignFee,
                danger: true,
              },
              {
                lable: '最终报价寄存费',
                type: 'amount',
                value: info?.orderConfirmAssessmentInfo?.consignFee,
                danger: true,
              },
              {
                lable: '与客户远程沟通确认估值结果截图',
                type: 'images',
                attachmentList:
                  info?.orderConfirmAssessmentInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 7,
                  ) ?? [],
              },
            ],
          },
          {
            title: '签约信息',
            renderer: !!info?.orderSignInfo,
            list: [
              {
                lable: '客户签约时间',
                type: 'text',
                value: info?.orderSignInfo?.signTime,
              },
              {
                lable: '到期赎回时间',
                type: 'text',
                value: info?.orderSignInfo?.expireRedeemTime,
              },
              {
                lable: '电子签约系统合同单',
                type: 'text',
                value: info?.orderSignInfo?.signContractCode,
              },
              {
                lable: '电子签约PDF电子文档',
                type: 'pdfs',
                attachmentList:
                  info?.orderSignInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 8,
                  ) ?? [],
              },
            ],
          },
          {
            title: '取消质押记录',
            renderer:
              !!info?.orderConfirmAssessmentInfo &&
              info?.orderConfirmAssessmentInfo?.isAcceptAssessment === 0,
            list: [
              {
                lable: '客户取消质押原因',
                type: 'dictTranslate',
                value: info?.orderConfirmAssessmentInfo?.refuseReason,
                dictCode: '0116',
              },
              {
                lable: '备注',
                type: 'text',
                value: info?.orderConfirmAssessmentInfo?.refuseRemark,
              },
            ],
          },
          {
            title: '回寄处理记录',
            renderer: !!info?.orderReturnInfo,
            list: [
              {
                lable: '是否现场收货',
                type: 'dictTranslate',
                value: info?.orderReturnInfo?.isLive,
                dictCode: '0123',
              },
              {
                renderer: !info?.orderReturnInfo?.isLive,
                lable: '快递单号',
                type: 'text',
                value: info?.orderReturnInfo?.courierNumber,
              },
              {
                renderer: !info?.orderReturnInfo?.isLive,
                lable: '寄件时间',
                type: 'text',
                value: info?.orderReturnInfo?.courierSentDate,
              },
              {
                renderer: !info?.orderReturnInfo?.isLive,
                lable: '客户收件地址',
                type: 'text',
                value: info?.orderReturnInfo?.shippingAddress,
              },
              {
                renderer: !info?.orderReturnInfo?.isLive,
                lable: '快递单号照片',
                type: 'images',
                attachmentList:
                  info?.orderReturnInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 9,
                  ) ?? [],
              },
            ],
          },
          {
            title: '回寄到货处理',
            renderer: !!info?.orderReturnArrivalInfo,
            list: [
              {
                lable: '收件时间',
                type: 'text',
                value: info?.orderReturnArrivalInfo?.receiptDate,
              },
              {
                lable: '客户收件签收照片上传',
                type: 'images',
                attachmentList:
                  info?.orderReturnArrivalInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 10,
                  ) ?? [],
              },
              {
                lable: '远程与客户确认收货截图照片上传',
                type: 'images',
                attachmentList:
                  info?.orderReturnArrivalInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 11,
                  ) ?? [],
              },
            ],
          },
        ];
      }
      return result;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss"></style>
