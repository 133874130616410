import { postInfo } from './api';

export default {
  // 获取估值参数分页列表
  getAssessmentParameterPageList(data) {
    return postInfo('/assessment/getAssessmentParameterPageList', data);
  },
  // 添加估值参数
  addAssessmentParameter(data) {
    return postInfo('/assessment/addAssessmentParameter', data);
  },
  // 删除估值参数
  deleteAssessmentParameter(data) {
    return postInfo('/assessment/deleteAssessmentParameter', data);
  },
  // 编辑估值参数
  modifyAssessmentParameter(data) {
    return postInfo('/assessment/modifyAssessmentParameter', data);
  },
  // 估价计算器
  assessmentCalculator(data) {
    return postInfo('/assessment/assessmentCalculator', data);
  },
};
