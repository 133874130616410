<template>
  <dynamic-order-info :title="title" :blocks="blocks"></dynamic-order-info>
</template>
<script>
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';

export default {
  name: 'InfoDeliver',
  components: {
    DynamicOrderInfo,
  },
  props: {
    info: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      title: '签约交付订单详情',
    };
  },
  computed: {
    blocks() {
      const { info } = this;
      let result = [];
      if (info) {
        result = [
          {
            title: '支付受理信息',
            renderer: !!info?.orderPaymentInfo,
            list: [
              {
                lable: '货款支付金额',
                type: 'amount',
                value: info?.orderPaymentInfo?.paymentAmount,
                danger: true,
              },
              {
                lable: '货款支付时间',
                type: 'text',
                value: info?.orderPaymentInfo?.paymentTime,
              },
              {
                lable: '支付处理人',
                type: 'text',
                value: info?.orderPaymentInfo?.payerName,
              },
              {
                lable: '支付账号种类',
                type: 'dictTranslate',
                value: info?.orderPaymentInfo?.paymentAccountType,
                dictCode: '0118',
              },
              {
                lable: '支付账单流水号',
                type: 'text',
                value: info?.orderPaymentInfo?.paymentSerialNumber,
              },
              {
                lable: '付款账号',
                type: 'text',
                value: info?.orderPaymentInfo?.paymentAccount,
              },
              {
                lable: '客户收款账号',
                type: 'text',
                value: info?.orderPaymentInfo?.creditedAccount,
              },
              {
                lable: '转账账单截图',
                type: 'images',
                attachmentList:
                  info?.orderPaymentInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 12,
                  ) ?? [],
              },
              {
                lable: '与客户远程确认货款到账截图',
                type: 'images',
                attachmentList:
                  info?.orderPaymentInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 13,
                  ) ?? [],
              },
              {
                lable: '该笔货款支付OA签报通过截图',
                type: 'images',
                attachmentList:
                  info?.orderPaymentInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 14,
                  ) ?? [],
              },
            ],
          },
          {
            title: '货品入库信息',
            renderer: !!info?.orderStoreInfo,
            list: [
              {
                lable: '货品入库时间',
                type: 'text',
                value: info?.orderStoreInfo?.storeTime,
              },
              {
                lable: '入库操作人',
                type: 'text',
                value: info?.orderStoreInfo?.storePersonName,
              },
              {
                lable: '库存单号',
                type: 'text',
                value: info?.orderStoreInfo?.storageCode,
              },
            ],
          },
        ];
      }

      return result;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss"></style>
