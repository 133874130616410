<template>
  <dynamic-order-info :title="title" :blocks="blocks"></dynamic-order-info>
</template>
<script>
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';

export default {
  name: 'InfoRealRight',
  components: {
    DynamicOrderInfo,
  },
  props: {
    info: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      title: '放弃物权订单详情',
    };
  },
  computed: {
    blocks() {
      const { info } = this;
      let result = [];
      if (info) {
        result = [
          {
            title: '放弃赎回信息',
            renderer: true,
            list: [
              {
                lable: '放弃物权申请时间',
                type: 'text',
                value: info?.waiveApplyTime,
              },
              {
                lable: '放弃物权操作人',
                type: 'text',
                value: info?.waiveApplyName,
              },
            ],
          },
          {
            title: '放弃赎回签约受理信息',
            renderer: info.isSign === 1,
            list: [
              {
                lable: '电子签约系统合同单号',
                type: 'text',
                value: info?.signContractCode,
              },
              {
                lable: '电子签约PDF电子文档',
                type: 'pdfs',
                attachmentList:
                  info?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 23,
                  ) ?? [],
              },
              {
                lable: '签约日期',
                type: 'text',
                value: info?.signDate,
              },
              {
                lable: '与客户远程沟通确认放弃赎回截图',
                type: 'images',
                attachmentList:
                  info?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 25,
                  ) ?? [],
              },
              {
                lable: '赎回确认处理人',
                type: 'text',
                value: info?.actorUserName,
              },
            ],
          },
          {
            title: '放弃赎回未签约受理信息',
            renderer: info.isSign === 0,
            list: [
              {
                lable: '不愿意放弃赎回签约原因',
                type: 'dictTranslate',
                value: info?.refuseReason,
                dictCode: '0120',
              },
              {
                lable: '请上传与客户联系后仍不愿意放弃赎回签约证明截图',
                type: 'images',
                attachmentList:
                  info?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 24,
                  ) ?? [],
              },
              {
                lable: '处理人',
                type: 'text',
                value: info?.actorUserName,
              },
            ],
          },
          {
            title: '物品处置受理',
            renderer: info.orderGoodsDisposal,
            list: [
              {
                lable: '物品处置金额',
                type: 'text',
                value: info?.orderGoodsDisposal?.disposalAmount,
              },
              {
                lable: '爱押分润比例',
                type: 'persent',
                value: info?.orderGoodsDisposal?.shareBenefitRate,
              },
              {
                lable: '爱押分润金额',
                type: 'amount',
                value: info?.orderGoodsDisposal?.shareBenefitAmount,
              },
              {
                lable: '合作商分润金额',
                type: 'amount',
                value: info?.orderGoodsDisposal?.partnerShareBenefitAmount,
              },
              {
                lable: '物品处置收款时间',
                type: 'text',
                value: info?.orderGoodsDisposal?.receiveAmountDate,
              },
              {
                lable: '收款处理人',
                type: 'text',
                value: info?.orderGoodsDisposal?.receivePersonName,
              },
              {
                lable: '收款账号属性',
                type: 'dictTranslate',
                value: info?.orderGoodsDisposal?.receiveAccountAttribute,
                dictCode: '0117',
              },
              {
                lable: '收款账号种类',
                type: 'dictTranslate',
                value: info?.orderGoodsDisposal?.receiveAccountType,
                dictCode: '0118',
              },
              {
                lable: '收款账单流水号',
                type: 'text',
                value: info?.orderGoodsDisposal?.receiveSerialNumber,
              },
              {
                lable: '收款账号',
                type: 'text',
                value: info?.orderGoodsDisposal?.receiveAccount,
              },
              {
                lable: '客户付款账号',
                type: 'text',
                value: info?.orderGoodsDisposal?.paymentAccount,
              },
              {
                lable: '转账账单截图',
                type: 'images',
                attachmentList:
                  info?.orderGoodsDisposal?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 26,
                  ) ?? [],
              },
              {
                lable: '与下游供应商确认物品处置金额截图',
                type: 'images',
                attachmentList:
                  info?.orderGoodsDisposal?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 27,
                  ) ?? [],
              },
              {
                lable: '该笔物品处置OA签报通过截图',
                type: 'images',
                attachmentList:
                  info?.orderGoodsDisposal?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 28,
                  ) ?? [],
              },
              {
                lable: '物品出库时间',
                type: 'text',
                value: info?.orderGoodsDisposal?.deliverTime,
              },
            ],
          },
        ];
      }
      return result;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss">
</style>
