<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="已取消质押待回寄受理"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
    @close="close"
    @closed="closed"
  >
    <div class="submit-form">
      <dynamic-order-info
        :blocks="blocks"
        v-loading="loading"
      ></dynamic-order-info>
      <el-form
        ref="form"
        size="small"
        label-width="170px"
        :model="model"
        :rules="rules"
      >
        <div class="form-block">
          <p class="form-block-title">回寄处理</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="是否现场收货" prop="isLive">
                <el-radio-group
                  v-model="model.isLive"
                  @change="value => change('isLive', value)"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="model.isLive === 0">
            <el-col :span="10">
              <el-form-item label="快递单号" prop="courierNumber">
                <el-input
                  v-model="model.courierNumber"
                  placeholder="请输入快递单号"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="寄件时间" prop="courierSentDate">
                <el-date-picker
                  v-model="model.courierSentDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="model.isLive === 0">
            <el-col :span="10">
              <el-form-item label="客户收件地址" prop="shippingAddress">
                <el-input
                  v-model="model.shippingAddress"
                  placeholder="请输入客户收件地址"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="model.isLive === 0">
            <el-col :span="24">
              <el-form-item label="快递单号照片上传" prop="attachment-9">
                <el-input v-show="false" v-model="model['attachment-9']">
                </el-input>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[9]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(9, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit(0)">
        保存
      </el-button>
      <el-button type="warning" :loading="submitLoading" @click="submit(1)">
        提交
      </el-button>
    </div>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';
import { mapState } from 'vuex';
import api from '../../../api/OrderManagement';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';
import getInfoMixin from '../../../mixins/get-info-mixin';

export default {
  name: 'WaitReturnAcceptance',
  components: {
    LUploadImg,
    DynamicOrderInfo,
  },
  props: {},
  mixins: [getInfoMixin, updateFilefieldMixin],
  data() {
    return {
      visible: false,
      id: '',
      loading: false,
      info: null,
      model: {
        isLive: '',
        courierNumber: '',
        courierSentDate: '',
        shippingAddress: '',
        'attachment-9': '',
      },
      rules: {
        isLive: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        courierNumber: [
          {
            required: true,
            message: '请输入',
          },
        ],
        courierSentDate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        shippingAddress: [
          {
            required: true,
            message: '请输入',
          },
        ],
        'attachment-9': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      mapOfAttachment: {},
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    blocks() {
      const orderAssessmentInfo = this.info?.orderAssessmentInfo;
      let result = [];
      if (orderAssessmentInfo) {
        result = [
          {
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productInfo,
              },
              {
                lable: '基础估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.basicPrice,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productSerialNumber,
              },
              {
                lable: '初始估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.initAssessment,
              },
              {
                lable: '远程与客户报价',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.priceQuotation,
              },
            ],
          },
          {
            title: '检测记录',
            list: [
              {
                lable: '系统最新估值',
                type: 'amount',
                value: orderAssessmentInfo?.orderDetectionInfo?.assessmentPrice,
              },
              {
                lable: '检测师估值',
                type: 'amount',
                value:
                  orderAssessmentInfo?.orderDetectionInfo?.detectionAssessment,
              },
              {
                lable: '检测师描述检测过程及估值判断依据',
                type: 'text',
                value: orderAssessmentInfo?.orderDetectionInfo?.detectionRemark,
              },
            ],
          },
          {
            title: '估值确认记录',
            list: [
              {
                lable: '确认估值金额',
                type: 'amount',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo
                    ?.assessmentPrice,
              },
              {
                lable: '确认寄存天数',
                type: 'dictTranslate',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo?.consignDays,
                dictCode: '0115',
              },
              {
                lable: '系统寄存费',
                type: 'amount',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo
                    ?.sysConsignFee,
                danger: true,
              },
              {
                lable: '折扣寄存费',
                type: 'amount',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo
                    ?.discountConsignFee,
                danger: true,
              },
              {
                lable: '最终报价寄存费',
                type: 'amount',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo?.consignFee,
                danger: true,
              },
              {
                lable: '与客户远程沟通确认估值结果截图',
                type: 'images',
                attachmentList:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 7,
                  ) ?? [],
              },
            ],
          },
          {
            title: '取消质押记录',
            list: [
              {
                lable: '客户取消质押原因',
                type: 'dictTranslate',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo?.refuseReason,
                dictCode: '0116',
              },
              {
                lable: '备注',
                type: 'text',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo?.refuseRemark,
              },
            ],
          },
        ];
      }
      return result;
    },
  },
  created() {},
  methods: {
    open(id) {
      this.id = id;
      this.loading = true;
      this.visible = true;
      this._getInfo();
      this._initSaveInfo('orderReturnCancel');
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.id = '';
      this.loading = false;
      this.submitLoading = false;
      this.info = null;
      this.mapOfAttachment = {};
    },
    submit(isSubmit) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi(isSubmit);
        }
      });
    },
    requestApi(isSubmit = 0) {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, model, mapOfAttachment } = this;

        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          isSubmit,
          orderId: id,
          ...model,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
          returnType: 1,
        };
        api
          .acceptReturn(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
    change(type) {
      switch (type) {
        case 'isLive':
          {
            const target = { ...this.model };
            Object.assign(target, {
              courierNumber: '',
              courierSentDate: '',
              shippingAddress: '',
              'attachment-9': '',
            });
            this.model = target;
            Reflect.deleteProperty(this.mapOfAttachment, 9);
            Reflect.deleteProperty(this.mixinInitAttachment, 9);
          }

          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
