<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="已收货待检测结束检测"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
    @close="close"
    @closed="closed"
  >
    <div class="submit-form">
      <dynamic-order-info
        :blocks="blocks"
        v-loading="loading"
      ></dynamic-order-info>
      <el-form
        ref="form"
        size="small"
        label-width="170px"
        :model="model"
        :rules="rules"
      >
        <div class="form-block">
          <p class="form-block-title">检测记录</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="检测开始时间" prop="detectionBeginTime">
                <el-date-picker
                  v-model="model.detectionBeginTime"
                  disabled
                  type="datetime"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button type="text" icon="el-icon-right" @click="assessment">
            进入评估页面
          </el-button>

          <el-row>
            <el-col :span="20">
              <el-table
                stripe
                border
                size="mini"
                :header-cell-style="{
                  background: '#f2f2f2',
                }"
                :data="orderQuestionList"
              >
                <el-table-column
                  v-for="(column, m) in columns"
                  :key="`column-${m}`"
                  :header-align="
                    column.headerAlign ? column.headerAlign : 'center'
                  "
                  :align="column.align ? column.align : 'center'"
                  :type="column.type"
                  :label="column.label"
                  :prop="column.prop"
                >
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="10">
              <el-form-item label="系统最新估值" prop="assessmentPrice">
                <el-input
                  v-model="model.assessmentPrice"
                  disabled
                  placeholder="进入评估页面进行估值"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="检测师估值" prop="detectionAssessment">
                <el-input
                  v-model="model.detectionAssessment"
                  placeholder="请输入检测师估值"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="检测师描述检测过程及估值判断依据"
                prop="detectionRemark"
              >
                <el-input
                  type="textarea"
                  v-model="model.detectionRemark"
                  :rows="4"
                  placeholder="请输入检测师描述检测过程及估值判断依据"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="是否拆机" prop="isDismantle">
                <el-radio-group v-model="model.isDismantle">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="请上传产品检测相关图片" prop="attachment-5">
                <el-input v-show="false" v-model="model['attachment-5']">
                </el-input>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[5]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(5, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="请上传产品检测视频录像文件"
                prop="attachment-6"
              >
                <el-input v-show="false" v-model="model['attachment-6']">
                </el-input>
                <drag-upload
                  v-if="visible"
                  accept="video/*"
                  :fileUrl="mixinInitAttachment[6]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(6, files)"
                ></drag-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit(0)">
        保存
      </el-button>
      <el-button type="warning" :loading="submitLoading" @click="submit(1)">
        提交
      </el-button>
    </div>
    <assessment ref="assessment" @submit="assessmentSubmit"></assessment>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';
import { mapState } from 'vuex';
import api from '../../../api/OrderManagement';
import Assessment from '../OrderList/Assessment.vue';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';
import getInfoMixin from '../../../mixins/get-info-mixin';
import DragUpload from '../../../components/DragUpload.vue';

export default {
  name: 'WaitCheckEndCheck',
  components: {
    LUploadImg,
    DynamicOrderInfo,
    Assessment,
    DragUpload,
  },
  props: {},
  mixins: [getInfoMixin, updateFilefieldMixin],
  data() {
    return {
      visible: false,
      id: '',
      loading: false,
      info: null,
      model: {
        detectionBeginTime: '',
        assessmentId: '',
        assessmentPrice: '',
        detectionAssessment: '',
        detectionRemark: '',
        isDismantle: null,
        'attachment-5': '',
        'attachment-6': '',
      },
      rules: {
        assessmentPrice: [
          {
            required: true,
            message: '请进入评估页面进行估值',
            trigger: 'change',
          },
        ],
        detectionAssessment: [
          {
            required: true,
            message: '请输入',
          },
        ],
        detectionRemark: [
          {
            required: true,
            message: '请输入',
          },
        ],
        isDismantle: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],

        'attachment-5': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-6': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      mapOfAttachment: {},
      columns: [
        {
          type: 'index',
          label: '序号',
        },
        {
          label: '问题项',
          prop: 'questionDescription',
        },
        {
          label: '选项',
          prop: 'optionDescription',
          headerAlign: 'center',
          align: 'left',
        },
        {
          label: '估值影响',
          prop: 'impactBasePrice',
        },
      ],
      orderQuestionList: [],
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    blocks() {
      const orderAssessmentInfo = this.info?.orderAssessmentInfo;
      let result = [];
      if (orderAssessmentInfo) {
        result = [
          {
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productInfo,
              },
              {
                lable: '基础估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.basicPrice,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productSerialNumber,
              },
            ],
          },
          {
            title: '初始评估结果',
            list: [
              {
                lable: '评估问题',
                type: 'text-table',
                text: '',
                columns: [
                  {
                    type: 'index',
                    label: '序号',
                  },
                  {
                    label: '问题项',
                    prop: 'questionDescription',
                  },
                  {
                    label: '选项',
                    prop: 'optionDescription',
                  },
                  {
                    label: '估值影响',
                    prop: 'impactBasePrice',
                  },
                ],
                tableData:
                  orderAssessmentInfo?.baseOrderInfo?.orderQuestionList ?? [],
              },
              {
                lable: '初始估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.initAssessment,
              },
              {
                lable: '远程与客户报价',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.priceQuotation,
              },
              {
                lable: '远程沟通后获取的产品相关图片',
                type: 'images',
                attachmentList:
                  orderAssessmentInfo?.baseOrderInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 3,
                  ) ?? [],
              },
            ],
          },
        ];
      }
      return result;
    },
  },
  created() {},
  methods: {
    open(id, detectionBeginTime) {
      this.id = id;

      const target = { ...this.model };
      Object.assign(target, {
        detectionBeginTime,
      });
      this.model = target;

      this.loading = true;
      this.visible = true;
      this._getInfo();
      this._initSaveInfo('orderDetection');
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.id = '';
      this.loading = false;
      this.submitLoading = false;
      this.info = null;
      this.mapOfAttachment = {};
      this.orderQuestionList = [];
    },
    submit(isSubmit) {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { assessmentId } = this.model;
          if (!assessmentId) {
            this.$message.warning('请先评估');
          } else {
            this.requestApi(isSubmit);
          }
        }
      });
    },
    requestApi(isSubmit = 0) {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, model, mapOfAttachment } = this;

        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          isSubmit,
          orderId: id,
          ...model,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
        };
        api
          .finishDetectionOrder(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
    assessment() {
      const { productId } = this.info.orderAssessmentInfo.baseOrderInfo;
      if (!productId) {
        this.$message.warning('没有产品机型无法评估');
        return false;
      }
      return this.$refs.assessment.open(productId);
    },
    assessmentSubmit({ assessmentId, assessmentAmount, orderQuestionList }) {
      const target = { ...this.model };
      Object.assign(target, {
        assessmentId,
        assessmentPrice: assessmentAmount,
      });
      this.model = target;
      this.orderQuestionList = orderQuestionList;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
