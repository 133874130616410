<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="已赎回待回寄受理"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
  >
    <div class="submit-form">
      <dynamic-order-info
        :blocks="blocks"
        v-loading="loading"
      ></dynamic-order-info>
      <el-form
        ref="form"
        size="small"
        label-width="170px"
        :model="model"
        :rules="rules"
      >
        <div class="form-block">
          <p class="form-block-title">赎回回寄受理</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="库存单号">
                <el-input
                  v-model="model.storageCode"
                  placeholder="库存单号"
                  disabled
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="是否现场收货" prop="isLive">
                <el-radio-group
                  v-model="model.isLive"
                  @change="value => change('isLive', value)"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="model.isLive === 0">
            <el-col :span="10">
              <el-form-item label="快递单号" prop="courierNumber">
                <el-input
                  v-model="model.courierNumber"
                  placeholder="请输入快递单号"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="寄件时间" prop="courierSentDate">
                <el-date-picker
                  v-model="model.courierSentDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="model.isLive === 0">
            <el-col :span="10">
              <el-form-item label="客户收件地址" prop="shippingAddress">
                <el-input
                  v-model="model.shippingAddress"
                  placeholder="请输入客户收件地址"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="model.isLive === 0">
            <el-col :span="24">
              <el-form-item label="快递单号照片上传" prop="attachment-17">
                <el-input v-show="false" v-model="model['attachment-17']">
                </el-input>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[17]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(17, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit(0)">
        保存
      </el-button>
      <el-button type="warning" :loading="submitLoading" @click="submit(1)">
        提交
      </el-button>
    </div>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import api from '../../../api/OrderManagement';
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';
import { mapState } from 'vuex';
import getInfoMixin from '../../../mixins/get-info-mixin';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';

export default {
  name: 'WaitRedeemAcceptance',
  components: {
    LUploadImg,
    DynamicOrderInfo,
  },
  props: {},
  mixins: [getInfoMixin, updateFilefieldMixin],
  data() {
    return {
      visible: false,
      id: '',
      info: null,
      loading: '',
      model: {
        isLive: '',
        storageCode: '',
        courierNumber: '',
        courierSentDate: '',
        shippingAddress: '',
        returnType: '2',
        'attachment-17': '',
      },
      rules: {
        isLive: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        courierNumber: [
          {
            required: true,
            message: '请输入',
          },
        ],
        courierSentDate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        shippingAddress: [
          {
            required: true,
            message: '请输入',
          },
        ],
        'attachment-17': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      mapOfAttachment: {},
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    blocks() {
      const orderAssessmentInfo = this.info?.orderAssessmentInfo;
      const result = [];
      if (orderAssessmentInfo) {
        const items = [
          {
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productInfo,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productSerialNumber,
              },
            ],
          },
        ];
        result.push(...items);
      }
      const orderExpireRedeemInfo = this.info?.orderExpireRedeemInfo;
      if (orderExpireRedeemInfo) {
        const items = [
          {
            title: '赎回信息',
            list: [
              {
                lable: '货款支付金额',
                type: 'amount',
                value:
                  orderExpireRedeemInfo?.orderRedeemAcceptInfo?.goodsAmount,
                danger: true,
              },
              {
                lable: '寄存天数',
                type: 'text',
                value:
                  orderExpireRedeemInfo?.orderRedeemAcceptInfo?.consignDays,
              },
              {
                lable: '寄存费用',
                type: 'amount',
                value: orderExpireRedeemInfo?.orderRedeemAcceptInfo?.consignFee,
                danger: true,
              },
              {
                lable: '赎回需支付总金额',
                type: 'amount',
                value:
                  orderExpireRedeemInfo?.orderRedeemAcceptInfo?.redeemAmount,
                danger: true,
              },
            ],
          },
          {
            title: '赎回受理信息',
            list: [
              {
                lable: '实际赎回日期',
                type: 'text',
                value:
                  orderExpireRedeemInfo?.orderRedeemAcceptInfo?.realRedeemDate,
              },
              {
                lable: '与客户远程沟通确认赎回截图',
                type: 'images',
                attachmentList:
                  orderExpireRedeemInfo?.orderRedeemAcceptInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 15,
                  ) ?? [],
              },
              {
                lable: '与客户支付货品赎回款截图',
                type: 'images',
                attachmentList:
                  orderExpireRedeemInfo?.orderRedeemAcceptInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 16,
                  ) ?? [],
              },
              {
                lable: '赎回款收款处理人',
                type: 'text',
                value:
                  orderExpireRedeemInfo?.orderRedeemAcceptInfo?.actorUserName,
              },
              {
                lable: '赎回款支付方式',
                type: 'dictTranslate',
                value:
                  orderExpireRedeemInfo?.orderRedeemAcceptInfo
                    ?.creditedAccountType,
                dictCode: '0118',
              },
              {
                lable: '赎回支付账单流水号',
                type: 'text',
                value:
                  orderExpireRedeemInfo?.orderRedeemAcceptInfo
                    ?.paymentSerialNumber,
              },
              {
                lable: '公司收款账号',
                type: 'text',
                value:
                  orderExpireRedeemInfo?.orderRedeemAcceptInfo?.creditedAccount,
              },
              {
                lable: '客户支付账号',
                type: 'text',
                value:
                  orderExpireRedeemInfo?.orderRedeemAcceptInfo?.paymentAccount,
              },
            ],
          },
        ];
        result.push(...items);
      }
      return result;
    },
  },
  created() {},
  methods: {
    open(storageCode, id) {
      this.id = id;
      this.model.storageCode = storageCode;
      this.loading = true;
      this.visible = true;
      this._getInfo();
      this._initSaveInfo('orderReturnRedeem');
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.id = '';
      this.loading = false;
      this.submitLoading = false;
      this.info = null;
      this.mapOfAttachment = {};
    },
    submit(isSubmit) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi(isSubmit);
        }
      });
    },
    requestApi(isSubmit = 0) {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, model, mapOfAttachment } = this;
        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          isSubmit,
          ...model,
          orderId: id,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
        };
        api
          .acceptRedeemReturn(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
    change(type) {
      switch (type) {
        case 'isLive':
          {
            const target = { ...this.model };
            Object.assign(target, {
              courierNumber: '',
              courierSentDate: '',
              shippingAddress: '',
              'attachment-17': '',
            });
            this.model = target;
            Reflect.deleteProperty(this.mapOfAttachment, 17);
            Reflect.deleteProperty(this.mixinInitAttachment, 17);
          }

          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
