<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="放弃赎回待签约受理"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
    @close="close"
    @closed="closed"
  >
    <div class="submit-form">
      <dynamic-order-info
        :blocks="blocks"
        v-loading="loading"
      ></dynamic-order-info>
      <el-form
        ref="form"
        size="small"
        label-width="170px"
        :model="model"
        :rules="rules"
      >
        <div class="form-block">
          <p class="form-block-title">放弃赎回待签约受理</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="客户是否愿意放弃赎回签约" prop="isSign">
                <el-switch
                  v-model="model.isSign"
                  :active-value="1"
                  :inactive-value="0"
                  @change="value => change('isSign', value)"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="model.isSign === 1">
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="电子签约系统合同单号"
                  prop="signContractCode"
                >
                  <el-input
                    v-model="model.signContractCode"
                    placeholder="请输入电子签约系统合同单号"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="请上传电子签约PDF电子文档"
                  prop="attachment-23"
                >
                  <el-input v-show="false" v-model="model['attachment-23']">
                  </el-input>
                  <drag-upload
                    v-if="visible"
                    accept="application/pdf"
                    :fileUrl="mixinInitAttachment[23]"
                    :folderName="'order'"
                    @getFile="files => _updateFilefield(23, files)"
                  ></drag-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="请选择签约日期" prop="signDate">
                  <el-date-picker
                    v-model="model.signDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="请上传与客户远程沟通确认续期截图"
                  prop="attachment-25"
                >
                  <el-input v-show="false" v-model="model['attachment-25']">
                  </el-input>
                  <l-upload-img
                    v-if="visible"
                    :fileUrl="mixinInitAttachment[25]"
                    :folderName="'order'"
                    @getFile="files => _updateFilefield(25, files)"
                  ></l-upload-img>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="放弃赎回确认处理人" prop="actorUserName">
                  <el-input
                    v-model="model.actorUserName"
                    placeholder="请搜索姓名"
                    readonly
                  >
                    <el-button
                      type="primary"
                      slot="append"
                      icon="el-icon-search"
                      @click="
                        select('select-sys-name', {
                          roleName: '',
                          type: 'actorUserId',
                        })
                      "
                    >
                    </el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div v-if="model.isSign === 0">
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="不愿意放弃赎回签约原因"
                  prop="refuseReason"
                >
                  <el-select
                    v-model="model.refuseReason"
                    placeholder="请选择原因"
                  >
                    <el-option
                      v-for="(option, optionIndex) in this.filterDictionary(
                        '0120',
                      )"
                      :key="`paymentAccountAttribute-${optionIndex}`"
                      :label="option.name"
                      :value="option.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="请上传与客户联系后仍不愿意放弃赎回签约证明截图"
                  prop="attachment-24"
                >
                  <el-input v-show="false" v-model="model['attachment-24']">
                  </el-input>
                  <l-upload-img
                    v-if="visible"
                    :fileUrl="mixinInitAttachment[24]"
                    :folderName="'order'"
                    @getFile="files => _updateFilefield(24, files)"
                  ></l-upload-img>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="处理人" prop="actorUserName">
                  <el-input
                    v-model="model.actorUserName"
                    placeholder="请搜索姓名"
                    readonly
                  >
                    <el-button
                      type="primary"
                      slot="append"
                      icon="el-icon-search"
                      @click="
                        select('select-sys-name', {
                          roleName: '',
                          type: 'actorUserId',
                        })
                      "
                    >
                    </el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit(0)">
        保存
      </el-button>
      <el-button type="warning" :loading="submitLoading" @click="submit(1)">
        提交
      </el-button>
    </div>
    <select-sys-name
      ref="select-sys-name"
      @selected="selectedName"
    ></select-sys-name>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import SelectSysName from '../../../components/SelectSysName.vue';
import api from '../../../api/OrderManagement';
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';
import { mapState } from 'vuex';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';
import getInfoMixin from '../../../mixins/get-info-mixin';
import DragUpload from '../../../components/DragUpload.vue';

export default {
  name: 'GiveUpRedeemAcceptance',
  components: {
    LUploadImg,
    SelectSysName,
    DynamicOrderInfo,
    DragUpload,
  },
  props: {},
  mixins: [getInfoMixin, updateFilefieldMixin],
  data() {
    return {
      visible: false,
      id: '',
      loading: false,
      info: null,
      model: {
        isSign: 1,
        signContractCode: '',
        'attachment-23': '',
        signDate: '',
        'attachment-25': '',
        actorUserId: '',
        actorUserName: '',
        refuseReason: '',
        'attachment-24': '',
      },
      rules: {
        isSign: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        signContractCode: [
          {
            required: true,
            message: '请输入',
          },
        ],
        'attachment-23': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        signDate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-25': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        actorUserName: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        refuseReason: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-24': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      mapOfAttachment: {},
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    blocks() {
      const orderAssessmentInfo = this.info?.orderAssessmentInfo;
      const result = [];
      if (orderAssessmentInfo) {
        const items = [
          {
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productInfo,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productSerialNumber,
              },
            ],
          },
        ];
        result.push(...items);
      }
      const orderExpireRedeemInfo = this.info?.orderExpireRedeemInfo;
      if (orderAssessmentInfo) {
        const items = [
          {
            title: '赎回信息',
            list: [
              {
                lable: '货款支付金额',
                type: 'amount',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.goodsAmount,
                danger: true,
              },
              {
                lable: '寄存天数',
                type: 'text',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.consignDays,
              },
              {
                lable: '寄存费用',
                type: 'amount',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.consignFee,
                danger: true,
              },
              {
                lable: '赎回需支付总金额',
                type: 'amount',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.redeemAmount,
                danger: true,
              },
            ],
          },
        ];
        result.push(...items);
      }
      const orderWaiveInfo = this.info?.orderWaiveInfo;
      if (orderWaiveInfo) {
        const items = [
          {
            title: '放弃赎回信息',
            list: [
              {
                lable: '放弃物权申请时间',
                type: 'text',
                value: orderWaiveInfo?.waiveApplyTime,
              },
              {
                lable: '放弃物权操作人',
                type: 'text',
                value: orderWaiveInfo?.waiveApplyName,
              },
            ],
          },
        ];
        result.push(...items);
      }
      return result;
    },
  },
  created() {},
  methods: {
    open(id) {
      this.loading = true;
      this.id = id;
      this.visible = true;
      this._getInfo();
      this._initSaveInfo('orderWaive');
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.id = '';
      this.loading = false;
      this.submitLoading = false;
      this.info = null;
      this.mapOfAttachment = {};
    },
    select(ref, context) {
      if (ref) {
        this.$refs[ref].open(context);
      }
      return true;
    },
    submit(isSubmit) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi(isSubmit);
        }
      });
    },
    selectedName(data, { type }) {
      const target = { ...this.model };
      switch (type) {
        case 'actorUserId':
          {
            const { id, userName } = data;
            Object.assign(target, {
              actorUserId: id,
              actorUserName: userName,
            });
          }
          break;
        default:
          break;
      }

      this.model = target;
    },
    requestApi(isSubmit = 0) {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, model, mapOfAttachment } = this;

        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          isSubmit,
          orderId: id,
          ...model,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
        };
        api
          .finishSignWaive(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
    change(type, value) {
      switch (type) {
        case 'isSign':
          {
            this.$refs.form.resetFields();
            const target = { ...this.model };
            Object.assign(target, {
              isSign: value,
            });
            this.model = target;
            this.mapOfAttachment = {};
            this.mixinInitAttachment = {};
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
