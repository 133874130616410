<template>
  <el-tabs :value="activeName" @tab-click="change">
    <el-tab-pane
      v-for="(item, index) in tabs"
      :key="`tabs-${index}`"
      :label="item.label"
      :name="item.component"
      :lazy="true"
    >
      <component
        :is="item.component"
        :ref="item.component"
        :info="item.info"
      ></component>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import WaitGoods from '../views/OrderManagement/TestValuation/WaitGoods.vue';
import WaitCheck from '../views/OrderManagement/TestValuation/WaitCheck.vue';
import ConfirmValuation from '../views/OrderManagement/TestValuation/ConfirmValuation.vue';
import WaitSignUp from '../views/OrderManagement/TestValuation/WaitSignUp.vue';
import WaitReturn from '../views/OrderManagement/TestValuation/WaitReturn.vue';
import ConfirmArrival from '../views/OrderManagement/TestValuation/ConfirmArrival.vue';
import Arrived from '../views/OrderManagement/TestValuation/Arrived.vue';

import WaitPayment from '../views/OrderManagement/ContractDelivery/WaitPayment.vue';
import CompletePayment from '../views/OrderManagement/ContractDelivery/CompletePayment.vue';

import WaitRedeem from '../views/OrderManagement/RedemptionAtMaturity/WaitRedeem.vue';
import RedeemedWaitReturn from '../views/OrderManagement/RedemptionAtMaturity/RedeemedWaitReturn.vue';
import WaitConfirmArrival from '../views/OrderManagement/RedemptionAtMaturity/WaitConfirmArrival.vue';
import ConfirmedArrival from '../views/OrderManagement/RedemptionAtMaturity/ConfirmedArrival.vue';

import DelayWaitPayment from '../views/OrderManagement/Renewal/DelayWaitPayment.vue';
import CarryOverEffect from '../views/OrderManagement/Renewal/CarryOverEffect.vue';

import GiveUpRedeem from '../views/OrderManagement/GiveUpRealRight/GiveUpRedeem.vue';
import SignedRealRight from '../views/OrderManagement/GiveUpRealRight/SignedRealRight.vue';
import NotSignedRealRight from '../views/OrderManagement/GiveUpRealRight/NotSignedRealRight.vue';

import InfoValuation from '../views/OrderManagement/OrderInfo/InfoValuation.vue';
import InfoDeliver from '../views/OrderManagement/OrderInfo/InfoDeliver.vue';
import InfoRedeem from '../views/OrderManagement/OrderInfo/InfoRedeem.vue';
import InfoRenewal from '../views/OrderManagement/OrderInfo/InfoRenewal.vue';
import InfoRealRight from '../views/OrderManagement/OrderInfo/InfoRealRight.vue';

export default {
  name: 'DynamicTabs',
  components: {
    WaitGoods,
    WaitCheck,
    ConfirmValuation,
    WaitSignUp,
    WaitReturn,
    ConfirmArrival,
    Arrived,

    WaitPayment,
    CompletePayment,

    WaitRedeem,
    RedeemedWaitReturn,
    WaitConfirmArrival,
    ConfirmedArrival,

    DelayWaitPayment,
    CarryOverEffect,

    GiveUpRedeem,
    SignedRealRight,
    NotSignedRealRight,

    InfoValuation,
    InfoDeliver,
    InfoRedeem,
    InfoRenewal,
    InfoRealRight,
  },
  props: {
    activeName: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    change({ name }) {
      this.$emit('update:activeName', name);
      this.doLayoutTable(name);
      this.updateData(name);
    },
    doLayoutTable(name) {
      if (this.$refs[name]) {
        const targetComponent = this.$refs[name].find(() => true);
        if (
          targetComponent &&
          targetComponent.$refs['dynamic-table'] &&
          targetComponent.$refs['dynamic-table'].doLayout
        ) {
          this.$nextTick(() => {
            targetComponent.$refs['dynamic-table'].doLayout();
          });
        }
      }
    },
    updateData(name) {
      if (this.$refs[name]) {
        const targetComponent = this.$refs[name].find(() => true);
        if (
          targetComponent &&
          targetComponent.getList &&
          typeof targetComponent.getList === 'function' &&
          !targetComponent.loading
        ) {
          this.$nextTick(() => {
            targetComponent.getList();
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
