<template>
  <el-dialog
    title="提示"
    :visible.sync="visible"
    append-to-body
    width="400px"
    destroy-on-close
    @close="close"
  >
    <el-alert
      title="入库操作"
      type="warning"
      description="你确定操作该货品的入库操作吗？"
      show-icon
      :closable="false"
    >
    </el-alert>
    <el-form
      ref="form"
      size="small"
      label-width="100px"
      :model="model"
      :rules="rules"
      label-suffix="："
    >
      <el-form-item label="入库仓号" prop="warehouseId">
        <el-select v-model="model.warehouseId" filterable placeholder="请选择入库仓号">
          <el-option
            v-for="item in warehouseIdS"
            :key="item.warehouseCode"
            :label="item.warehouseCode"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="confirm" :disabled="!model.warehouseId">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import api from '../../../api/OrderManagement';
import { mapState } from 'vuex';

export default {
  name: 'Warehousing',
  components: {},
  props: {},
  data() {
    return {
      modelId: '',
      orderId: '',
      userId: '',
      visible: false,
      warehouseIdS: [],
      model: {
        warehouseId: '',
      },
      rules: {
        warehouseId: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
  },
  created() {},
  methods: {
    open(orderId, modelId, userId) {
      this.orderId = orderId;
      this.modelId = modelId;
      this.userId = userId;
      this.visible = true;
      this.getWarehouseIdS();
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    getWarehouseIdS() {
      const promise = new Promise((resolve, reject) => {
        const ext = {
          status: 0,
          isPage: 0,
        };
        api
          .getWarehouseList(ext)
          .then(res => {
            const { datas } = res.result;
            this.warehouseIdS = datas;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          });
      });
      return promise;
    },
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi();
        }
      });
    },
    requestApi() {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { orderId, modelId, userId, model } = this;
        const ext = {
          orderId,
          modelId,
          userId,
          ...model,
          storePersonId: this.userInfo.userId,
        };
        api
          .putStorage(ext)
          .then(res => {
            this.$message.success('入库成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-alert {
  margin-bottom: 25px;
}
</style>
