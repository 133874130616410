<template>
  <dynamic-order-info :title="title" :blocks="blocks"></dynamic-order-info>
</template>
<script>
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';

export default {
  name: 'InfoRenewal',
  components: {
    DynamicOrderInfo,
  },
  props: {
    info: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: '续期订单详情',
    };
  },
  computed: {
    blocks() {
      const { info } = this;
      const results = [];
      if (info.length) {
        info.forEach(item => {
          const result = [
            {
              title: '续期信息',
              renderer: true,
              list: [
                {
                  lable: '确认续期天数',
                  type: 'text',
                  value: item?.renewalDays,
                },
                {
                  lable: '续期费用',
                  type: 'amount',
                  value: item?.renewalFee,
                },
                {
                  lable: '爱押分润比例',
                  type: 'persent',
                  value: item?.shareBenefitRate,
                },
                {
                  lable: '爱押分润金额',
                  type: 'amount',
                  value: item?.shareBenefitAmount,
                },
                {
                  lable: '合作商分润金额',
                  type: 'amount',
                  value: item?.partnerShareBenefitAmount,
                  danger: true,
                },
                {
                  lable: '续期申请时间',
                  type: 'text',
                  value: item?.renewalApplyTime,
                },
              ],
            },
            {
              title: '续期签约定金收取信息',
              renderer: true,
              list: [
                {
                  lable: '电子签约系统合同单号',
                  type: 'text',
                  value: item?.signContractCode,
                },
                {
                  lable: '电子签约PDF电子文档',
                  type: 'pdfs',
                  attachmentList:
                    item?.attachmentInfoList?.filter(
                      ({ attachmentType }) => attachmentType === 20,
                    ) ?? [],
                },
                {
                  lable: '实际续期日期',
                  type: 'text',
                  value: item?.realRenewalDate,
                },
                {
                  lable: '客户远程沟通确认续期截图',
                  type: 'images',
                  attachmentList:
                    item?.attachmentInfoList?.filter(
                      ({ attachmentType }) => attachmentType === 21,
                    ) ?? [],
                },
                {
                  lable: '客户支付续期定金截图',
                  type: 'images',
                  attachmentList:
                    item?.attachmentInfoList?.filter(
                      ({ attachmentType }) => attachmentType === 22,
                    ) ?? [],
                },
                {
                  lable: '续期定金支付时间',
                  type: 'text',
                  value: item?.feePaymentTime,
                },
                {
                  lable: '续期定金收款处理人',
                  type: 'text',
                  value: item?.actorUserName,
                },
                {
                  lable: '续期定金账号种类',
                  type: 'text',
                  value: item?.creditedAccountType,
                },
                {
                  lable: '续期定金支付账单流水号',
                  type: 'text',
                  value: item?.paymentSerialNumber,
                },
                {
                  lable: '公司收款账号',
                  type: 'text',
                  value: item?.creditedAccount,
                },
                {
                  lable: '客户支付账号',
                  type: 'text',
                  value: item?.paymentAccount,
                },
              ],
            },
          ];
          results.push(...result);
        });
      }
      return results;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss">
</style>
