<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="已签约待支付受理"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
    @close="close"
    @closed="closed"
  >
    <div class="submit-form">
      <dynamic-order-info
        :blocks="blocks"
        v-loading="loading"
      ></dynamic-order-info>
      <el-form
        ref="form"
        size="small"
        label-width="170px"
        :model="model"
        :rules="rules"
      >
        <div class="form-block">
          <p class="form-block-title">支付受理</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="货款支付金额" prop="paymentAmount">
                <el-input v-model="model.paymentAmount"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="货款支付时间" prop="paymentTime">
                <el-date-picker
                  v-model="model.paymentTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="支付处理人" prop="payerName">
                <el-input
                  v-model="model.payerName"
                  placeholder="请搜索员工账号"
                  readonly
                >
                  <el-button
                    type="primary"
                    slot="append"
                    icon="el-icon-search"
                    @click="select('select-sys-name')"
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="支付账号属性" prop="accountAttribute">
                <el-select
                  v-model="model.accountAttribute"
                  placeholder="请选择支付账号属性"
                >
                  <el-option
                    v-for="(option, optionIndex) in this.filterDictionary(
                      '0117',
                    )"
                    :key="`accountAttribute-${optionIndex}`"
                    :label="option.name"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="支付账号种类" prop="accountType">
                <el-select
                  v-model="model.accountType"
                  placeholder="请选择支付账号种类"
                >
                  <el-option
                    v-for="(option, optionIndex) in this.filterDictionary(
                      '0118',
                    )"
                    :key="`accountType-${optionIndex}`"
                    :label="option.name"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="支付账单流水号" prop="paymentSerialNumber">
                <el-input
                  v-model="model.paymentSerialNumber"
                  placeholder="请输入"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="付款账号" prop="paymentAccount">
                <el-input
                  v-model="model.paymentAccount"
                  placeholder="请搜索公司付款账号"
                  readonly
                >
                  <el-button
                    type="primary"
                    slot="append"
                    icon="el-icon-search"
                    @click="
                      select('select-account', {
                        type: 'paymentAccount',
                        accountAttribute: model.accountAttribute,
                        accountType: model.accountType,
                        accountUse: 1,
                      })
                    "
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="客户收款账号" prop="creditedAccount">
                <el-input
                  v-model="model.creditedAccount"
                  placeholder="请输入客户收款账号"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="请上传转账账单截图" prop="attachment-12">
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[12]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(12, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="请上传与客户远程确认货款到账截图"
                prop="attachment-13"
              >
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[13]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(13, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="请上传该笔货款支付OA签报通过截图"
                prop="attachment-14"
              >
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[14]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(14, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit(0)">
        保存
      </el-button>
      <el-button type="warning" :loading="submitLoading" @click="submit(1)">
        提交
      </el-button>
    </div>
    <select-sys-name
      ref="select-sys-name"
      @selected="selectedName"
    ></select-sys-name>
    <select-account
      ref="select-account"
      @selected="selectedAccount"
    ></select-account>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';
import { mapState } from 'vuex';
import SelectSysName from '../../../components/SelectSysName.vue';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';
import getInfoMixin from '../../../mixins/get-info-mixin';
import api from '../../../api/OrderManagement';
import SelectAccount from '../../../components/SelectAccount.vue';

export default {
  name: 'WaitPaymentAcceptance',
  components: {
    LUploadImg,
    DynamicOrderInfo,
    SelectSysName,
    SelectAccount,
  },
  props: {},
  mixins: [getInfoMixin, updateFilefieldMixin],
  data() {
    return {
      visible: false,
      id: '',
      loading: false,
      info: null,
      model: {
        paymentAmount: '',
        paymentTime: '',
        payerId: '',
        payerName: '',
        paymentSerialNumber: '',
        paymentAccount: '',
        creditedAccount: '',
        accountAttribute: '',
        accountType: '',
        'attachment-12': '',
        'attachment-13': '',
        'attachment-14': '',
      },
      rules: {
        paymentTime: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        payerName: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        paymentSerialNumber: [
          {
            required: true,
            message: '请输入',
          },
        ],
        paymentAccount: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        creditedAccount: [
          {
            required: true,
            message: '请输入',
          },
        ],
        'attachment-12': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-13': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-14': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      mapOfAttachment: {},
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    blocks() {
      const orderAssessmentInfo = this.info?.orderAssessmentInfo;
      let result = [];
      if (orderAssessmentInfo) {
        result = [
          {
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productInfo,
              },
              {
                lable: '基础估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.basicPrice,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productSerialNumber,
              },
            ],
          },
          {
            title: '初始评估结果',
            list: [
              {
                lable: '评估问题',
                type: 'text-table',
                text: '',
                columns: [
                  {
                    type: 'index',
                    label: '序号',
                  },
                  {
                    label: '问题项',
                    prop: 'questionDescription',
                  },
                  {
                    label: '选项',
                    prop: 'optionDescription',
                  },
                  {
                    label: '估值影响',
                    prop: 'impactBasePrice',
                  },
                ],
                tableData:
                  orderAssessmentInfo?.baseOrderInfo?.orderQuestionList ?? [],
              },
              {
                lable: '初始估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.initAssessment,
              },
              {
                lable: '远程与客户报价',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.priceQuotation,
              },
              {
                lable: '远程沟通后获取的产品相关图片',
                type: 'images',
                attachmentList:
                  orderAssessmentInfo?.baseOrderInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 3,
                  ) ?? [],
              },
            ],
          },
          {
            title: '估值确认记录',
            list: [
              {
                lable: '确认估值金额',
                type: 'amount',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo
                    ?.assessmentPrice,
              },
              {
                lable: '支付金额',
                type: 'amount',
                value:
                orderAssessmentInfo?.orderConfirmAssessmentInfo?.paymentAmount,
                danger: true,
              },
              {
                lable: '确认寄存天数',
                type: 'dictTranslate',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo?.consignDays,
                dictCode: '0115',
              },
              {
                lable: '确认寄存手续费',
                type: 'amount',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo?.consignFee,
                danger: true,
              },
              {
                lable: '与客户远程沟通确认估值结果截图',
                type: 'images',
                attachmentList:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 7,
                  ) ?? [],
              },
            ],
          },
          {
            title: '签约信息',
            list: [
              {
                lable: '电子签约系统合同单号',
                type: 'text',
                value: orderAssessmentInfo?.orderSignInfo?.signContractCode,
              },
              {
                lable: '客户签约时间',
                type: 'text',
                value: orderAssessmentInfo?.orderSignInfo?.signTime,
              },
              {
                lable: '电子签约PDF电子文档',
                type: 'pdfs',
                attachmentList:
                  orderAssessmentInfo?.orderSignInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 7,
                  ) ?? [],
              },
            ],
          },
        ];
      }
      return result;
    },
  },
  created() {},
  methods: {
    open(id) {
      this.id = id;
      this.loading = true;
      this.visible = true;
      this._getInfo().then(() => {
        const target = { ...this.model };
        Object.assign(target, {
          paymentAmount: this.info?.orderAssessmentInfo
            ?.orderConfirmAssessmentInfo?.paymentAmount,
        });
        this.model = target;
      });
      this._initSaveInfo('orderPayment');
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.id = '';
      this.loading = false;
      this.submitLoading = false;
      this.info = null;
      this.mapOfAttachment = {};
    },
    select(ref, context) {
      if (ref) {
        if (ref === 'select-account') {
          const { accountAttribute, accountType } = this.model;
          if (!accountAttribute) {
            this.$message.warning('请先选择支付账号属性');
            return false;
          }
          if (!accountType) {
            this.$message.warning('请先选择支付账号种类');
            return false;
          }
        }
        this.$refs[ref].open(context);
      }
      return true;
    },
    submit(isSubmit) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi(isSubmit);
        }
      });
    },
    requestApi(isSubmit = 0) {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, model, mapOfAttachment } = this;

        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          isSubmit,
          orderId: id,
          ...model,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
        };
        api
          .acceptPayment(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
    selectedName({ id, userName }) {
      const target = { ...this.model };
      Object.assign(target, {
        payerId: id,
        payerName: userName,
      });

      this.model = target;
    },
    selectedAccount({ accountNumber }) {
      const target = { ...this.model };
      Object.assign(target, {
        paymentAccount: accountNumber,
      });

      this.model = target;
    },
  },
};
</script>

<style lang="scss" scoped></style>
