<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="物品处置受理"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
    @close="close"
    @closed="closed"
  >
    <div class="submit-form">
      <dynamic-order-info
        :blocks="blocks"
        v-loading="loading"
      ></dynamic-order-info>
      <el-form
        ref="form"
        size="small"
        label-width="170px"
        :model="model"
        :rules="rules"
      >
        <div class="form-block">
          <p class="form-block-title">物品处置受理</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="物品处置金额（元）" prop="disposalAmount">
                <el-input v-model="model.disposalAmount" placeholder="请输入">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="爱押分润比例"
                prop="shareBenefitRate"
              >
                <el-select
                  v-model="model.shareBenefitRate"
                  placeholder="爱押分润比例"
                  @change="value => change('shareBenefitRate', value)"
                >
                  <el-option
                    v-for="(option, optionIndex) in this.filterDictionary(
                      '0130',
                    )"
                    :key="`shareBenefitRate-${optionIndex}`"
                    :label="option.name"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="爱押分润金额"
                prop="shareBenefitAmount"
              >
                <el-input
                  v-model="model.shareBenefitAmount"
                  readonly
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="合作商分润金额"
                prop="partnerShareBenefitAmount"
              >
                <el-input
                  v-model="model.partnerShareBenefitAmount"
                  readonly
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="物品处置收款时间" prop="receiveAmountDate">
                <el-date-picker
                  v-model="model.receiveAmountDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="收款处理人" prop="receivePersonName">
                <el-input
                  v-model="model.receivePersonName"
                  placeholder="请搜索姓名"
                  readonly
                >
                  <el-button
                    type="primary"
                    slot="append"
                    icon="el-icon-search"
                    @click="
                      select('select-sys-name', {
                        roleName: '',
                        type: 'receivePersonId',
                      })
                    "
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="收款账号属性" prop="accountAttribute">
                <el-select
                  v-model="model.accountAttribute"
                  placeholder="请选择账号属性"
                  @change="value => change('accountAttribute', value)"
                >
                  <el-option
                    v-for="(option, optionIndex) in this.filterDictionary(
                      '0126',
                    )"
                    :key="`accountAttribute-${optionIndex}`"
                    :label="option.name"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="收款账号种类" prop="accountType">
                <el-select
                  v-model="model.accountType"
                  placeholder="请选择账号种类"
                  @change="value => change('accountType', value)"
                >
                  <el-option
                    v-for="(option, optionIndex) in this.filterDictionary(
                      '0127',
                    )"
                    :key="`accountType-${optionIndex}`"
                    :label="option.name"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="收款账单流水号" prop="receiveSerialNumber">
                <el-input
                  v-model="model.receiveSerialNumber"
                  placeholder="请输入"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="收款账号" prop="receiveAccount">
                <el-input
                  v-model="model.receiveAccount"
                  placeholder="请搜索公司收款账号"
                  readonly
                >
                  <el-button
                    type="primary"
                    slot="append"
                    icon="el-icon-search"
                    @click="
                      select('select-account', {
                        type: 'receiveAccount',
                        accountAttribute: model.accountAttribute,
                        accountType: model.accountType,
                        accountUse: 2,
                      })
                    "
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="客户付款账号" prop="paymentAccount">
                <el-input
                  v-model="model.paymentAccount"
                  placeholder="请输入客户支付账号"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="请上传转账账单截图" prop="attachment-26">
                <el-input v-show="false" v-model="model['attachment-26']">
                </el-input>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[26]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(26, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="请上传与下游供应商确认物品处置金额截图"
                prop="attachment-27"
              >
                <el-input v-show="false" v-model="model['attachment-27']">
                </el-input>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[27]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(27, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="请上传该笔物品处置OA签报通过截图"
                prop="attachment-28"
              >
                <el-input v-show="false" v-model="model['attachment-28']">
                </el-input>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[28]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(28, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit(0)">
        保存
      </el-button>
      <el-button type="warning" :loading="submitLoading" @click="submit(1)">
        提交
      </el-button>
    </div>
    <select-sys-name
      ref="select-sys-name"
      @selected="selectedName"
    ></select-sys-name>
    <select-account
      ref="select-account"
      @selected="selectedName"
    ></select-account>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import SelectSysName from '../../../components/SelectSysName.vue';
import api from '../../../api/OrderManagement';
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';
import { mapState } from 'vuex';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';
import getInfoMixin from '../../../mixins/get-info-mixin';
import SelectAccount from '../../../components/SelectAccount.vue';

export default {
  name: 'DisposalOfArticles',
  components: {
    LUploadImg,
    SelectSysName,
    DynamicOrderInfo,
    SelectAccount,
  },
  props: {},
  mixins: [getInfoMixin, updateFilefieldMixin],
  data() {
    return {
      visible: false,
      id: '',
      loading: false,
      info: null,
      model: {
        disposalAmount: '',
        receiveAmountDate: '',
        receivePersonId: '',
        receivePersonName: '',
        accountAttribute: '',
        accountType: '',
        receiveSerialNumber: '',
        receiveAccount: '',
        paymentAccount: '',
        'attachment-26': '',
        'attachment-27': '',
        'attachment-28': '',
        shareBenefitRate: '',
        shareBenefitAmount: '',
        partnerShareBenefitAmount: '',
      },
      rules: {
        disposalAmount: [
          {
            required: true,
            message: '请输入',
          },
        ],
        receiveAmountDate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        receivePersonName: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        accountAttribute: [
          {
            required: true,
            message: '请添加',
            trigger: 'change',
          },
        ],
        accountType: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        receiveSerialNumber: [
          {
            required: true,
            message: '请输入',
          },
        ],
        receiveAccount: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        paymentAccount: [
          {
            required: true,
            message: '请输入',
          },
        ],
        'attachment-26': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-27': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-28': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        shareBenefitRate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        shareBenefitAmount: [
          {
            required: true,
            message: '待计算',
          },
        ],
        partnerShareBenefitAmount: [
          {
            required: true,
            message: '待计算',
          },
        ],
      },
      mapOfAttachment: {},
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    blocks() {
      const orderAssessmentInfo = this.info?.orderAssessmentInfo;
      const orderSignPaymentInfo = this.info?.orderSignPaymentInfo;
      const result = [];
      if (orderAssessmentInfo) {
        const items = [
          {
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productInfo,
              },
              {
                lable: '基础估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.basicPrice,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productSerialNumber,
              },
              {
                lable: '初始估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.initAssessment,
              },
              {
                lable: '远程与客户报价',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.priceQuotation,
              },
            ],
          },
          {
            title: '检测记录',
            list: [
              {
                lable: '系统最新估值',
                type: 'amount',
                value: orderAssessmentInfo?.orderDetectionInfo?.assessmentPrice,
              },
              {
                lable: '检测师估值',
                type: 'amount',
                value:
                  orderAssessmentInfo?.orderDetectionInfo?.detectionAssessment,
              },
              {
                lable: '检测师描述检测过程及估值判断依据',
                type: 'text',
                value: orderAssessmentInfo?.orderDetectionInfo?.detectionRemark,
              },
            ],
          },
          {
            title: '估值确认记录',
            list: [
              {
                lable: '确认估值金额',
                type: 'amount',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo
                    ?.assessmentPrice,
              },
              {
                lable: '确认寄存天数',
                type: 'dictTranslate',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo?.consignDays,
                dictCode: '0115',
              },
              {
                lable: '确认寄存手续费',
                type: 'amount',
                value:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo?.consignFee,
                danger: true,
              },
              {
                lable: '与客户远程沟通确认估值结果截图',
                type: 'images',
                attachmentList:
                  orderAssessmentInfo?.orderConfirmAssessmentInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 7,
                  ) ?? [],
              },
            ],
          },
          {
            title: '库存信息',
            list: [
              {
                lable: '库存单号',
                type: 'text',
                value: orderSignPaymentInfo?.orderStoreInfo?.storageCode,
              },
              {
                lable: '货仓编号',
                type: 'text',
                value: orderSignPaymentInfo?.orderStoreInfo?.warehouseCode,
              },
            ],
          },
        ];
        result.push(...items);
      }

      return result;
    },
  },
  created() {},
  methods: {
    open(id) {
      this.loading = true;
      this.id = id;
      this.visible = true;
      this._getInfo();
      this._initSaveInfo('orderGoodsDisposal');
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.id = '';
      this.loading = false;
      this.submitLoading = false;
      this.info = null;
      this.mapOfAttachment = {};
    },
    select(ref, context) {
      if (ref) {
        if (ref === 'select-account') {
          const { accountAttribute, accountType } = this.model;
          if (!accountAttribute) {
            this.$message.warning('请先选择收款账号属性');
            return false;
          }
          if (!accountType) {
            this.$message.warning('请先选择收款账号种类');
            return false;
          }
        }
        this.$refs[ref].open(context);
      }
      return true;
    },
    submit(isSubmit) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi(isSubmit);
        }
      });
    },
    selectedName(data, { type }) {
      const target = { ...this.model };
      switch (type) {
        case 'receivePersonId':
          {
            const { id, userName } = data;
            Object.assign(target, {
              receivePersonId: id,
              receivePersonName: userName,
            });
          }
          break;
        case 'receiveAccount':
          {
            const { accountNumber } = data;
            Object.assign(target, {
              receiveAccount: accountNumber,
            });
          }
          break;
        default:
          break;
      }

      this.model = target;
    },
    requestApi(isSubmit = 0) {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, model, mapOfAttachment } = this;

        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          isSubmit,
          orderId: id,
          ...model,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
        };
        api
          .goodsDisposal(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
    change(type) {
      switch (type) {
        case 'accountAttribute':
          {
            const target = { ...this.model };
            Object.assign(target, {
              receiveAccount: '',
            });
            this.model = target;
          }
          break;
        case 'accountType':
          {
            const target = { ...this.model };
            Object.assign(target, {
              receiveAccount: '',
            });
            this.model = target;
          }
          break;
        case 'shareBenefitRate':
          {
            const goodsAmount = this.info.orderSignPaymentInfo.orderPaymentInfo.paymentAmount ?
                  this.info.orderSignPaymentInfo.orderPaymentInfo.paymentAmount : 0;
            const profitAmount = (this.model.disposalAmount - goodsAmount).toFixed(2);
            this.model.shareBenefitAmount = ((profitAmount * this.model.shareBenefitRate) / 100).toFixed(2);
            this.model.partnerShareBenefitAmount = profitAmount - this.model.shareBenefitAmount;
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
