<template>
  <el-drawer
    custom-class="order-info-drawer"
    title="订单详情"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
    @closed="closed"
  >
    <div class="info-drawer" v-loading="loading">
      <div v-if="timeline.length" class="tool">
        <el-button type="primary" icon="el-icon-timer" size="mini" @click="openTimeline">
          订单时间轴
        </el-button>
      </div>
      <dynamic-tabs :activeName.sync="activeName" :tabs="filterTabs"></dynamic-tabs>
    </div>
    <order-timeline :timeline="timeline" ref="timeline"></order-timeline>
  </el-drawer>
</template>
<script>
import DynamicTabs from '../../../components/DynamicTabs.vue';
import OrderTimeline from './OrderTimeline.vue';
import api from '../../../api/OrderManagement';
import oss from '../../../utils/oss';

const client = oss.client();

export default {
  name: 'OrderInfoIndex',
  components: {
    DynamicTabs,
    OrderTimeline,
  },
  props: {},
  data() {
    return {
      id: '',
      visible: false,
      loading: false,
      activeName: 'info-valuation',
      tabs: [
        {
          label: '检测估值',
          component: 'info-valuation',
          info: null,
        },
        {
          label: '签约交付',
          component: 'info-deliver',
          info: null,
        },
        {
          label: '期满赎回',
          component: 'info-redeem',
          info: null,
        },
        {
          label: '续期',
          component: 'info-renewal',
          info: null,
        },
        {
          label: '放弃物权',
          component: 'info-real-right',
          info: null,
        },
      ],
      timeline: [],
    };
  },
  computed: {
    filterTabs() {
      return this.tabs.filter(({ info }) => !!info);
    },
  },
  created() {},
  methods: {
    init(id) {
      this.id = id;
      this.getInfo();
      this.getTimeline();
    },
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    closed() {
      let target = [...this.tabs];
      target = target.map(item => ({
        ...item,
        info: null,
      }));
      this.tabs = target;
      this.timeline = [];
    },
    openTimeline() {
      this.$refs.timeline.open();
    },
    getInfo() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { id } = this;
        api
          .getOrderInfo(id)
          .then(res => {
            this.createData(res.result);
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    createData(raw) {
      const target = [...this.tabs];
      ({
        orderAssessmentInfo: target[0].info,
        orderSignPaymentInfo: target[1].info,
        orderExpireRedeemInfo: target[2].info,
        orderRenewalInfo: target[3].info,
        orderWaiveInfo: target[4].info,
      } = raw);
      this.formatData(target);
      this.tabs = target;
      if (this.filterTabs.length) {
        this.activeName = this.filterTabs[0].component;
      }
    },
    formatData(target) {
      if (target !== null && typeof target === 'object') {
          this.generateValidUrl(target.attachmentInfoList);
          Object.values(target).forEach(point => this.formatData(point));
      }
    },
    generateValidUrl(list) {
      if (list && Array.isArray(list)) {
        list.forEach(point => {
          const { attachmentPath } = point;
          if (attachmentPath) {
            point.url = client.signatureUrl(attachmentPath);
          }
        });
      }
    },
    getTimeline() {
      const promise = new Promise((resolve, reject) => {
        const { id } = this;
        api
          .getOrderTimeline(id)
          .then(res => {
            this.timeline = res.result;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {});
      });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped></style>
