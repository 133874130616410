<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="待收货受理"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
    @close="close"
    @closed="closed"
  >
    <div class="submit-form">
      <dynamic-order-info :blocks="blocks" v-loading="loading"></dynamic-order-info>
      <el-form ref="form" size="small" label-width="170px" :model="model" :rules="rules">
        <div class="form-block">
          <p class="form-block-title">快递/现场收件处理</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="快递/现场收件日期" prop="courierReceiveDate">
                <el-date-picker
                  v-model="model.courierReceiveDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="快递/现场收件人" prop="courierReceiverName">
                <el-input v-model="model.courierReceiverName" readonly placeholder="请搜索员工账号">
                  <el-button
                    type="primary"
                    slot="append"
                    icon="el-icon-search"
                    @click="
                      select('select-sys-name', {
                        roleName: '',
                        type: 'courierReceiverId',
                      })
                    "
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="签收单照片上传" prop="attachment-4">
                <el-input v-show="false" v-model="model['attachment-4']"> </el-input>
                <el-link type="primary" disabled>
                  只能上传jpg/png格式文件，单个文件不能超过500kb
                </el-link>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[4]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(4, files)"
                >
                </l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="收货机型是否一致" prop="modelIsConsistent">
                <el-radio-group
                  v-model="model.modelIsConsistent"
                  @change="value => change('modelIsConsistent', value)"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="model.modelIsConsistent === 0">
            <el-row>
              <el-col :span="10">
                <el-form-item label="收货机型不一致描述" prop="inconsistentRemark">
                  <el-input
                    type="textarea"
                    v-model="model.inconsistentRemark"
                    :rows="4"
                    placeholder="请输入收货机型不一致描述"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="实物照片上传" prop="attachment-29">
                  <el-input v-show="false" v-model="model['attachment-29']"> </el-input>
                  <l-upload-img
                    v-if="visible"
                    :fileUrl="mixinInitAttachment[29]"
                    :folderName="'order'"
                    @getFile="files => _updateFilefield(29, files)"
                  ></l-upload-img>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row>
            <el-col :span="10">
              <el-form-item label="检测工程师" prop="detectionEngineerName">
                <el-input
                  v-model="model.detectionEngineerName"
                  readonly
                  placeholder="请搜索员工账号"
                >
                  <el-button
                    type="primary"
                    slot="append"
                    icon="el-icon-search"
                    @click="
                      select('select-sys-name', {
                        roleName: '检测工程师,城市合作商',
                        type: 'detectionEngineerId',
                      })
                    "
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="预计检测日期" prop="expectedDetectionDate">
                <el-date-picker
                  v-model="model.expectedDetectionDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit(0)">
        保存
      </el-button>
      <el-button type="warning" :loading="submitLoading" @click="submit(1)">
        提交
      </el-button>
    </div>
    <select-sys-name ref="select-sys-name" @selected="selectedName"></select-sys-name>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import SelectSysName from '../../../components/SelectSysName.vue';
import api from '../../../api/OrderManagement';
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';
import { mapState } from 'vuex';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';
import getInfoMixin from '../../../mixins/get-info-mixin';

export default {
  name: 'WaitGoodsAcceptance',
  components: {
    LUploadImg,
    SelectSysName,
    DynamicOrderInfo,
  },
  props: {},
  mixins: [getInfoMixin, updateFilefieldMixin],
  data() {
    return {
      visible: false,
      id: '',
      loading: false,
      info: null,
      model: {
        courierReceiveDate: '',
        courierReceiverId: '',
        courierReceiverName: '',
        'attachment-4': '',
        modelIsConsistent: '',
        detectionEngineerId: '',
        detectionEngineerName: '',
        expectedDetectionDate: '',

        inconsistentRemark: '',
        'attachment-29': '',
      },
      rules: {
        courierReceiveDate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        courierReceiverName: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-4': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        modelIsConsistent: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        detectionEngineerName: [
          {
            required: true,
            message: '请输入',
            trigger: 'change',
          },
        ],
        expectedDetectionDate: [
          {
            required: true,
            message: '请输入',
            trigger: 'change',
          },
        ],
        inconsistentRemark: [
          {
            required: true,
            message: '请选择',
          },
        ],
        'attachment-29': [
          {
            required: true,
            message: '请添加',
            trigger: 'change',
          },
        ],
      },
      mapOfAttachment: {},
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    blocks() {
      const orderAssessmentInfo = this.info?.orderAssessmentInfo;
      let result = [];
      if (orderAssessmentInfo) {
        result = [
          {
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productInfo,
              },
              {
                lable: '基础估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.basicPrice,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productSerialNumber,
              },
            ],
          },
          {
            title: '初始评估结果',
            list: [
              {
                lable: '评估问题',
                type: 'text-table',
                text: '',
                columns: [
                  {
                    type: 'index',
                    label: '序号',
                  },
                  {
                    label: '问题项',
                    prop: 'questionDescription',
                  },
                  {
                    label: '选项',
                    prop: 'optionDescription',
                  },
                  {
                    label: '估值影响',
                    prop: 'impactBasePrice',
                  },
                ],
                tableData: orderAssessmentInfo?.baseOrderInfo?.orderQuestionList ?? [],
              },
              {
                lable: '初始估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.initAssessment,
              },
              {
                lable: '远程与客户报价',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.priceQuotation,
              },
              {
                lable: '远程沟通后获取的产品相关图片',
                type: 'images',
                attachmentList:
                  orderAssessmentInfo?.baseOrderInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 3,
                  ) ?? [],
              },
            ],
          },
          {
            title: '快递单号信息',
            list: [
              {
                lable: '是否现场收货',
                type: 'dictTranslate',
                value: orderAssessmentInfo?.baseOrderInfo?.isLive,
                dictCode: '0123',
              },
              {
                renderer: !orderAssessmentInfo?.baseOrderInfo?.isLive,
                lable: '快递单号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.courierNumber,
              },
              {
                renderer: !orderAssessmentInfo?.baseOrderInfo?.isLive,
                lable: '寄件时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.courierSentDate,
              },
            ],
          },
        ];
      }
      return result;
    },
  },
  created() {},
  methods: {
    open(id) {
      this.loading = true;
      this.id = id;
      this.visible = true;
      this._getInfo();
      this._initSaveInfo('orderReceiving');
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.id = '';
      this.loading = false;
      this.submitLoading = false;
      this.info = null;
      this.mapOfAttachment = {};
    },
    change(type) {
      switch (type) {
        case 'modelIsConsistent':
          {
            const target = { ...this.model };
            Object.assign(target, {
              inconsistentRemark: '',
              'attachment-29': '',
            });
            this.model = target;
            Reflect.deleteProperty(this.mapOfAttachment, 29);
            Reflect.deleteProperty(this.mixinInitAttachment, 29);
          }

          break;

        default:
          break;
      }
    },

    select(ref, context) {
      if (ref) {
        this.$refs[ref].open(context);
      }
    },
    submit(isSubmit) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi(isSubmit);
        }
      });
    },

    selectedName({ id, userName }, { type }) {
      const target = { ...this.model };
      switch (type) {
        case 'courierReceiverId':
          Object.assign(target, {
            courierReceiverId: id,
            courierReceiverName: userName,
          });
          break;
        case 'detectionEngineerId':
          Object.assign(target, {
            detectionEngineerId: id,
            detectionEngineerName: userName,
          });
          break;
        default:
          break;
      }

      this.model = target;
    },

    requestApi(isSubmit = 0) {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, model, mapOfAttachment } = this;

        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          isSubmit,
          orderId: id,
          ...model,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
        };

        api
          .acceptReceivingOrder(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped></style>
