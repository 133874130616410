import { postInfo } from './api';

export default {
  // 插入用户
  insertUser(data) {
    return postInfo('/user/insertSysUser', data);
  },
  // 获取用户列表
  getUserList(data) {
    return postInfo('/user/getSysUserList', data);
  },
  // 更新用户用户
  updateUser(data) {
    return postInfo('/user/updateSysUser', data);
  },
  // 删除用户
  deleteUser(data) {
    return postInfo('/user/deleteSysUser', data);
  },
  // 获取角色列表
  getRoleList(data) {
    return postInfo('/role/getRoleList', data);
  },
  // 修改密码
  modifyPassword(data) {
    return postInfo('/user/modifyPassword', data);
  },
};
