<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="已回寄待确认到货（赎回）受理"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
    @close="close"
    @closed="closed"
  >
    <div class="submit-form">
      <dynamic-order-info
        :blocks="blocks"
        v-loading="loading"
      ></dynamic-order-info>
      <el-form
        ref="form"
        size="small"
        label-width="170px"
        :model="model"
        :rules="rules"
      >
        <div class="form-block">
          <p class="form-block-title">回寄到货处理</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="收件时间" prop="receiptDate">
                <el-date-picker
                  v-model="model.receiptDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="客户收件签收照片上传" prop="attachment-18">
                <el-input v-show="false" v-model="model['attachment-18']">
                </el-input>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[18]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(18, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="远程与客户确认收货截图照片上传"
                prop="attachment-19"
              >
                <el-input v-show="false" v-model="model['attachment-19']">
                </el-input>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[19]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(19, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit(0)">
        保存
      </el-button>
      <el-button type="warning" :loading="submitLoading" @click="submit(1)">
        提交
      </el-button>
    </div>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import api from '../../../api/OrderManagement';
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';
import { mapState } from 'vuex';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';
import getInfoMixin from '../../../mixins/get-info-mixin';

export default {
  name: 'WaitRedeemAcceptance',
  components: {
    LUploadImg,
    DynamicOrderInfo,
  },
  props: {},
  mixins: [getInfoMixin, updateFilefieldMixin],
  data() {
    return {
      visible: false,
      id: '',
      loading: false,
      info: null,
      model: {
        receiptDate: '',
        'attachment-18': '',
        'attachment-19': '',
      },
      rules: {
        receiptDate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-18': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-19': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      mapOfAttachment: {},
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    blocks() {
      const orderAssessmentInfo = this.info?.orderAssessmentInfo;
      const result = [];
      if (orderAssessmentInfo) {
        const items = [
          {
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productInfo,
              },
              {
                lable: '基础估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.basicPrice,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productSerialNumber,
              },
            ],
          },
        ];
        result.push(...items);
      }
      const orderExpireRedeemInfo = this.info?.orderExpireRedeemInfo;
      if (orderExpireRedeemInfo) {
        const items = [
          {
            title: '回寄记录',
            list: [
              {
                lable: '快递单号',
                type: 'text',
                value:
                  orderExpireRedeemInfo?.orderRedeemReturnInfo?.courierNumber,
              },
              {
                lable: '寄件时间',
                type: 'text',
                value:
                  orderExpireRedeemInfo?.orderRedeemReturnInfo?.courierSentDate,
              },
              {
                lable: '客户收件地址',
                type: 'text',
                value:
                  orderExpireRedeemInfo?.orderRedeemReturnInfo?.shippingAddress,
              },
              {
                lable: '快递单号照片',
                type: 'images',
                attachmentList:
                  orderExpireRedeemInfo?.orderRedeemReturnInfo?.attachmentInfoList?.filter(
                    ({ attachmentType }) => attachmentType === 17,
                  ) ?? [],
              },
            ],
          },
        ];
        result.push(...items);
      }
      return result;
    },
  },
  created() {},
  methods: {
    open(id) {
      this.id = id;
      this.loading = true;
      this.visible = true;
      this._getInfo();
      this._initSaveInfo('orderReturnArrivalRedeem');
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.id = '';
      this.loading = false;
      this.submitLoading = false;
      this.info = null;
      this.mapOfAttachment = {};
    },
    submit(isSubmit) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi(isSubmit);
        }
      });
    },
    requestApi(isSubmit = 0) {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, model, mapOfAttachment } = this;

        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          isSubmit,
          orderId: id,
          ...model,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
          returnType: 2,
        };
        api
          .acceptRedeemArrival(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped></style>
