<template>
  <el-dialog
    custom-class="table-select-dialog"
    title="选择人员"
    :visible.sync="visible"
    append-to-body
    destroy-on-close
    @opened="getList"
    @closed="closed"
  >
    <dynamic-form
      :config="{
        labelWidth: '110px',
      }"
      :formItems="formItems"
      :model.sync="model"
    ></dynamic-form>
    <search-reset
      :loading="loading"
      :model.sync="model"
      @search="getList"
    ></search-reset>
    <dynamic-table
      :config="config"
      :loading="loading"
      :columns="columns"
      :tableData="tableData"
      :selectRow.sync="selectRow"
      :pagination.sync="pagination"
      @update="getList"
    ></dynamic-table>
    <span slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="confirm" :disabled="!selectRow">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import DynamicForm from './DynamicForm.vue';
import SearchReset from './SearchReset.vue';
import DynamicTable from './DynamicTable.vue';
import api from '../api/UserManageApi';

export default {
  name: 'SelectSysName',
  components: {
    DynamicForm,
    SearchReset,
    DynamicTable,
  },
  props: {},
  data() {
    return {
      visible: false,
      model: {},
      formItems: [
        {
          label: '姓名',
          type: 'text',
          name: 'userName',
          placeholder: '请输入姓名',
        },
      ],
      loading: false,
      config: {
        stripe: false,
        highlightCurrentRow: true,
      },
      columns: [
        {
          type: 'index',
          label: '序号',
        },
        {
          label: 'ID',
          prop: 'id',
        },
        {
          label: '姓名',
          prop: 'userName',
        },
      ],
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      },
      selectRow: null,
      context: {},
    };
  },
  computed: {},
  created() {},
  methods: {
    open(context) {
      this.context = context;
      this.loading = true;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    closed() {
      this.model = {};
      this.tableData = [];
      this.selectRow = null;
      this.pagination = {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      };
      this.loading = false;
      this.context = {};
    },
    getList() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, pagination, context } = this;
        const { currentPage, pageSize } = pagination;
        const ext = {
          pageNum: currentPage,
          pageSize,
          ...model,
          ...context,
        };
        api
          .getUserList(ext)
          .then(res => {
            const { datas, totalRecords } = res.result;
            this.tableData = datas;
            this.pagination.total = totalRecords;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    confirm() {
      if (!this.selectRow) {
        this.$message.warning('请选择');
        return false;
      }
      this.$emit('selected', this.selectRow, this.context);
      return this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
