<template>
  <el-upload
    ref="elupload"
    drag
    multiple
    action=""
    :accept="accept"
    :auto-upload="true"
    :file-list="showFileList"
    :before-upload="beforeUpload"
    :http-request="httpRequest"
    :on-success="handleSuccess"
    :on-error="handlEerror"
    :on-remove="handleRemove"
  >
    <i class="el-icon-upload"></i>
    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    <div class="el-upload__tip" slot="tip">文件大小不能超过20M！</div>
  </el-upload>
</template>

<script>
import oss from '@/utils/oss';

export default {
  name: 'DragUpload',
  props: {
    accept: {
      type: String,
      default: 'image/*,video/*',
    },
    fileUrl: {
      type: [String],
      default: '',
    },
    folderName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    showFileList: {
      get() {
        const client = oss.client();
        const imgArr = this.fileUrl ? this.fileUrl.split(',') : [];
        const imgUrlArr = imgArr.map(val => {
          const remoteUrl = client.signatureUrl(val);
          const obj = {
            name: val,
            url: remoteUrl,
          };
          return obj;
        });
        return imgUrlArr;
      },
      set(val) {
        return val;
      },
    },
    folderNames() {
      // 去除开头和结尾的 '/' 字符
      const folderName = this.folderName.replace(/(^\/+)|(\/+$)/g, '');
      return folderName;
    },
  },
  methods: {
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error('文件大小不能超过20M！');
        return false;
      }
      return true;
    },
    httpRequest(option) {
      const { file } = option;
      const client = oss.client();
      const myDate = new Date();
      const year = myDate.getFullYear();
      const month =
        myDate.getMonth() > 8
          ? `${myDate.getMonth() + 1}`
          : `0${myDate.getMonth() + 1}`;
      const day = myDate.getDate();
      const loading = this.$loading({
        lock: true,
        text: '上传中，请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const arr = file.name.split('.');
      arr.splice(arr.length - 1, 0, myDate.valueOf());
      client
        .put(
          `iya/${this.folderNames}/${year}${month}${day}/${arr.join('.')}`,
          file,
        )
        .then(res => {
          option.onSuccess(res);
        })
        .catch(err => {
          option.onError(err);
        })
        .finally(() => {
          loading.close();
        });
    },
    handlEerror() {
      this.$message.error('上传失败，请重试！');
    },
    handleSuccess(response, file, fileList) {
      this.$message.success('上传成功！');
      // const { name, url } = response;
      // const obj = {
      //   name,
      //   url,
      // };
      // const addList = this.showFileList;
      // addList.push(obj);
      // console.log(addList);

      const list = fileList.map(item => {
        const newItem = {
          name: item?.response?.name ?? item.name,
          url: item?.response?.url ?? item.url,
        };
        return newItem;
      });
      this.$emit('getFile', list);
    },
    handleRemove(file, fileList) {
      const remList = [];
      fileList.forEach(val => {
        const obj = {
          name: val.response ? val.response.name : val.name,
          url: val.response ? val.response.url : val.url,
        };
        remList.push(obj);
      });
      this.$emit('getFile', remList);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
