<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="确认延期待签约付定金受理"
    :visible.sync="visible"
    size="100%"
    destroy-on-close
    @close="close"
    @closed="closed"
  >
    <div class="submit-form">
      <dynamic-order-info
        :blocks="blocks"
        v-loading="loading"
      ></dynamic-order-info>
      <el-form
        ref="form"
        size="small"
        label-width="170px"
        :model="model"
        :rules="rules"
      >
        <div class="form-block">
          <p class="form-block-title">续期签约收定金受理</p>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="爱押分润比例"
                prop="shareBenefitRate"
              >
                <el-select
                  v-model="model.shareBenefitRate"
                  placeholder="爱押分润比例"
                  @change="value => change('shareBenefitRate', value)"
                >
                  <el-option
                    v-for="(option, optionIndex) in this.filterDictionary(
                      '0130',
                    )"
                    :key="`shareBenefitRate-${optionIndex}`"
                    :label="option.name"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="爱押分润金额"
                prop="shareBenefitAmount"
              >
                <el-input
                  v-model="model.shareBenefitAmount"
                  readonly
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="合作商分润金额"
                prop="partnerShareBenefitAmount"
              >
                <el-input
                  v-model="model.partnerShareBenefitAmount"
                  readonly
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="电子签约系统合同单号"
                prop="signContractCode"
              >
                <el-input
                  v-model="model.signContractCode"
                  placeholder="请输入电子签约系统合同单号"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="请上传电子签约PDF电子文档"
                prop="attachment-20"
              >
                <el-input v-show="false" v-model="model['attachment-20']">
                </el-input>
                <drag-upload
                  v-if="visible"
                  accept="application/pdf"
                  :fileUrl="mixinInitAttachment[20]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(20, files)"
                ></drag-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="请选择实际续期日期" prop="realRenewalDate">
                <el-date-picker
                  v-model="model.realRenewalDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="请上传与客户远程沟通确认续期截图"
                prop="attachment-21"
              >
                <el-input v-show="false" v-model="model['attachment-21']">
                </el-input>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[21]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(21, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="请上传与客户支付续期定金截图"
                prop="attachment-22"
              >
                <el-input v-show="false" v-model="model['attachment-22']">
                </el-input>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[22]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(22, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="续期定金支付时间" prop="feePaymentTime">
                <el-date-picker
                  v-model="model.feePaymentTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="续期定金收款处理人" prop="actorUserName">
                <el-input
                  v-model="model.actorUserName"
                  placeholder="请搜索姓名"
                  readonly
                >
                  <el-button
                    type="primary"
                    slot="append"
                    icon="el-icon-search"
                    @click="
                      select('select-sys-name', {
                        roleName: '',
                        type: 'actorUserId',
                      })
                    "
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="续期定金收款账号属性"
                prop="accountAttribute"
              >
                <el-select
                  v-model="model.accountAttribute"
                  placeholder="请选择账号属性"
                  @change="value => change('accountAttribute', value)"
                >
                  <el-option
                    v-for="(option, optionIndex) in this.filterDictionary(
                      '0126',
                    )"
                    :key="`accountAttribute-${optionIndex}`"
                    :label="option.name"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="续期定金收款账号种类" prop="accountType">
                <el-select
                  v-model="model.accountType"
                  placeholder="请选择账号种类"
                  @change="value => change('accountType', value)"
                >
                  <el-option
                    v-for="(option, optionIndex) in this.filterDictionary(
                      '0127',
                    )"
                    :key="`accountType-${optionIndex}`"
                    :label="option.name"
                    :value="option.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="续期定金支付账单流水号"
                prop="paymentSerialNumber"
              >
                <el-input
                  v-model="model.paymentSerialNumber"
                  placeholder="请输入"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="公司收款账号" prop="creditedAccount">
                <el-input
                  v-model="model.creditedAccount"
                  placeholder="请搜索公司收款账号"
                  readonly
                >
                  <el-button
                    type="primary"
                    slot="append"
                    icon="el-icon-search"
                    @click="
                      select('select-account', {
                        type: 'creditedAccount',
                        accountAttribute: model.accountAttribute,
                        accountType: model.accountType,
                        accountUse: 2,
                      })
                    "
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="客户支付账号" prop="paymentAccount">
                <el-input
                  v-model="model.paymentAccount"
                  placeholder="请输入客户支付账号"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit(0)">
        保存
      </el-button>
      <el-button type="warning" :loading="submitLoading" @click="submit(1)">
        提交
      </el-button>
    </div>
    <select-sys-name
      ref="select-sys-name"
      @selected="selectedName"
    ></select-sys-name>
    <select-account
      ref="select-account"
      @selected="selectedName"
    ></select-account>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import SelectSysName from '../../../components/SelectSysName.vue';
import api from '../../../api/OrderManagement';
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';
import { mapState } from 'vuex';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';
import getInfoMixin from '../../../mixins/get-info-mixin';
import DragUpload from '../../../components/DragUpload.vue';
import SelectAccount from '../../../components/SelectAccount.vue';

export default {
  name: 'DelayWaitPaymentAcceptance',
  components: {
    LUploadImg,
    SelectSysName,
    DynamicOrderInfo,
    DragUpload,
    SelectAccount,
  },
  props: {},
  mixins: [getInfoMixin, updateFilefieldMixin],
  data() {
    return {
      visible: false,
      id: '',
      renewalId: '',
      loading: false,
      info: null,
      model: {
        signContractCode: '',
        'attachment-20': '',
        realRenewalDate: '',
        'attachment-21': '',
        'attachment-22': '',
        feePaymentTime: '',
        actorUserId: '',
        actorUserName: '',

        accountAttribute: '',
        accountType: '',

        paymentSerialNumber: '',
        creditedAccount: '',
        paymentAccount: '',
        renewalFee: '',
        shareBenefitRate: '',
        shareBenefitAmount: '',
        partnerShareBenefitAmount: '',
      },
      rules: {
        signContractCode: [
          {
            required: true,
            message: '请输入',
          },
        ],
        'attachment-20': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        realRenewalDate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-21': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        'attachment-22': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        feePaymentTime: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        actorUserName: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        accountAttribute: [
          {
            required: true,
            message: '请添加',
            trigger: 'change',
          },
        ],
        accountType: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        paymentSerialNumber: [
          {
            required: true,
            message: '请输入',
          },
        ],
        creditedAccount: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        paymentAccount: [
          {
            required: true,
            message: '请输入',
          },
        ],
        shareBenefitRate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        shareBenefitAmount: [
          {
            required: true,
            message: '待计算',
          },
        ],
        partnerShareBenefitAmount: [
          {
            required: true,
            message: '待计算',
          },
        ],
      },
      mapOfAttachment: {},
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    blocks() {
      const orderAssessmentInfo = this.info?.orderAssessmentInfo;
      const result = [];
      if (orderAssessmentInfo) {
        const items = [
          {
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productInfo,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productSerialNumber,
              },
            ],
          },
        ];
        result.push(...items);
      }
      const orderExpireRedeemInfo = this.info?.orderExpireRedeemInfo;
      if (orderAssessmentInfo) {
        const items = [
          {
            title: '赎回信息',
            list: [
              {
                lable: '货款支付金额',
                type: 'amount',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.goodsAmount,
                danger: true,
              },
              {
                lable: '寄存天数',
                type: 'text',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.consignDays,
              },
              {
                lable: '寄存费用',
                type: 'amount',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.consignFee,
                danger: true,
              },
              {
                lable: '赎回需支付总金额',
                type: 'amount',
                value: orderExpireRedeemInfo?.orderRedeemInfo?.redeemAmount,
                danger: true,
              },
            ],
          },
        ];
        result.push(...items);
      }
      const orderRenewalInfo = this.info?.orderRenewalInfo;
      if (orderRenewalInfo && Array.isArray(orderRenewalInfo)) {
        orderRenewalInfo.forEach(item => {
          const items = [
            {
              title: '续期信息',
              list: [
                {
                  lable: '确认续期天数',
                  type: 'text',
                  value: item?.renewalDays,
                },
                {
                  lable: '续期费用',
                  type: 'amount',
                  value: item?.renewalFee,
                  danger: true,
                },
                {
                  lable: '续期申请时间',
                  type: 'text',
                  value: item?.renewalApplyTime,
                },
              ],
            },
          ];
          result.push(...items);
        });
      }
      return result;
    },
  },
  created() {},
  methods: {
    open(id, renewalId) {
      this.loading = true;
      this.id = id;
      this.renewalId = renewalId;
      this.visible = true;
      this._getInfo();
      this._initSaveInfo('orderRenewal');
      this.renewalFee = this.info.renewalFee;
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.id = '';
      this.renewalId = '';
      this.loading = false;
      this.submitLoading = false;
      this.info = null;
      this.mapOfAttachment = {};
    },
    select(ref, context) {
      if (ref) {
        if (ref === 'select-account') {
          const { accountAttribute, accountType } = this.model;
          if (!accountAttribute) {
            this.$message.warning('请先选择续期定金收款账号属性');
            return false;
          }
          if (!accountType) {
            this.$message.warning('请先选择续期定金收款账号种类');
            return false;
          }
        }
        this.$refs[ref].open(context);
      }
      return true;
    },
    submit(isSubmit) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi(isSubmit);
        }
      });
    },
    selectedName(data, { type }) {
      const target = { ...this.model };
      switch (type) {
        case 'actorUserId':
          {
            const { id, userName } = data;
            Object.assign(target, {
              actorUserId: id,
              actorUserName: userName,
            });
          }
          break;
        case 'creditedAccount':
          {
            const { accountNumber } = data;
            Object.assign(target, {
              creditedAccount: accountNumber,
            });
          }
          break;
        default:
          break;
      }

      this.model = target;
    },
    requestApi(isSubmit = 0) {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, renewalId, model, mapOfAttachment } = this;

        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          isSubmit,
          orderId: id,
          renewalId,
          ...model,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
        };
        api
          .finishRenewalSign(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
    change(type) {
      switch (type) {
        case 'accountAttribute':
          {
            const target = { ...this.model };
            Object.assign(target, {
              creditedAccount: '',
            });
            this.model = target;
          }
          break;
        case 'accountType':
          {
            const target = { ...this.model };
            Object.assign(target, {
              creditedAccount: '',
            });
            this.model = target;
          }
          break;
        case 'shareBenefitRate':
          this.model.shareBenefitAmount = Math.ceil((this.model.renewalFee * this.model.shareBenefitRate) / 100);
          this.model.partnerShareBenefitAmount = this.model.renewalFee - this.model.shareBenefitAmount;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
