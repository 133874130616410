<template>
  <el-dialog title="订单类型" :visible.sync="visible" append-to-body width="600px">
    <div>
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in timeline"
          :key="`list-${index}`"
          :color="`#009688`"
        >
          <el-popover placement="right" trigger="click" title="订单状态">
            <div slot="reference" class="timeline-item-body">
              <el-button slot="reference" type="text">
                {{ item.orderPhaseName }}
              </el-button>
              <br />
              <span class="timestamp">{{ item.orderPhaseTime }}</span>
            </div>

            <el-timeline
              ><el-timeline-item
                v-for="(citem, j) in item.orderStatusList"
                :key="`list-${index}-children-${j}`"
                :timestamp="citem.orderStatusTime"
                :color="`#009688`"
              >
                {{ citem.orderStatusName }}
              </el-timeline-item>
            </el-timeline>
          </el-popover>
        </el-timeline-item>
      </el-timeline>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'OrderTimeline',
  components: {},
  props: {
    timeline: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-item-body {
  display: inline-block;
}
.timestamp {
  color: #909399;
  line-height: 1;
  font-size: 13px;
}
</style>
