<template>
  <div class="dynamic-order-info">
    <p v-if="title" class="title">{{ title }}</p>
    <div class="blocks">
      <template v-for="(block, index) in blocks">
        <div
          v-if="block.renderer === undefined || block.renderer"
          :key="`blocks-${index}`"
          class="block"
        >
          <p v-if="block.title" class="block-title">{{ block.title }}</p>
          <template v-for="(cell, j) in block.list">
            <div
              v-if="cell.renderer === undefined || cell.renderer"
              class="block-body"
              :key="`blocks-${index}-list-${j}`"
              :class="{ danger: cell.danger }"
            >
              <label>{{ cell.lable }}：</label>
              <div v-if="cell.type === 'text-table'" class="text-table">
                <p v-if="cell.text">
                  {{ cell.text }}
                </p>
                <el-table
                  v-if="cell.tableData.length"
                  stripe
                  border
                  size="mini"
                  :header-cell-style="{ background: '#f2f2f2' }"
                  :data="cell.tableData"
                >
                  <el-table-column
                    v-for="(column, m) in cell.columns"
                    :key="`blocks-${index}-list-${j}-column-${m}`"
                    align="center"
                    :type="column.type"
                    :label="column.label"
                    :prop="column.prop"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <div v-else-if="cell.type === 'images'" class="images">
                <el-image
                  v-for="(attachment, m) in cell.attachmentList"
                  :key="`blocks-${index}-list-${j}-attachment-${m}`"
                  :src="attachment.url"
                  fit="cover"
                  :preview-src-list="
                    generatePreviewList(cell.attachmentList, m)
                  "
                >
                </el-image>
                <el-image
                  v-if="false && !cell.attachmentList.length"
                  class="image-null"
                >
                  <div slot="error">暂无</div>
                </el-image>
              </div>
              <div v-else-if="cell.type === 'videos'" class="videos">
                <video
                  v-for="(attachment, m) in cell.attachmentList"
                  :key="`blocks-${index}-list-${j}-attachment-${m}`"
                  :src="attachment.url"
                  controls="controls"
                ></video>
              </div>
              <div v-else-if="cell.type === 'pdfs'" class="links">
                <el-link
                  v-for="(attachment, m) in cell.attachmentList"
                  :key="`blocks-${index}-list-${j}-attachment-${m}`"
                  type="primary"
                  :href="attachment.url"
                  target="_blank"
                >
                  {{ attachment.attachmentName }}
                </el-link>
              </div>

              <div v-else-if="cell.type === 'dictTranslate'">
                {{ valueToName(filterDictionary(cell.dictCode), cell.value) }}
              </div>
              <div v-else-if="cell.type === 'amount'">
                <template v-if="toThousands(cell.value)">
                  {{ toThousands(cell.value) }} 元
                </template>
              </div>
              <div v-else-if="cell.type === 'persent'">
                {{ toPersent(cell.value) }}
              </div>
              <div v-else>
                {{ cell.value }}
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicOrderInfo',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    blocks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    generatePreviewList(images, index) {
      const list = [];
      const { length } = images;
      for (let i = 0; i < length; i += 1) {
        list.push(images[i + index].url);
        if (i + index >= length - 1) {
          index = 0 - (i + 1);
        }
      }
      return list;
    },
    toThousands(n) {
      if (n === '') return '';
      if (Number.isNaN(Number(n))) return '';
      let nStr = (n || 0).toString();
      let sign = '';
      let int = '';
      let decimal = '';
      if (nStr.includes('-')) {
        sign = '-';
        nStr = nStr.replace(/-/, '');
      }
      if (nStr.includes('.')) {
        const arr = nStr.split('.');
        [int, decimal] = arr;
      } else {
        int = nStr;
      }

      decimal = decimal.padEnd(2, '0');

      let result = '';
      while (int.length > 3) {
        result = `,${int.slice(-3)}${result}`;
        int = int.slice(0, int.length - 3);
      }
      if (int) {
        result = int + result;
      }
      if (decimal) {
        result = `${result}.${decimal}`;
      }
      if (sign) {
        result = `${sign}${result}`;
      }
      return result;
    },
    toPersent(n) {
      if (n) {
        return `${n}%`;
      }
        return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.dynamic-order-info {
  padding-bottom: 150px;
  height: calc(100vh - 52px - 10px - 28px - 40px - 15px - 15px);
  font-size: 14px;
  overflow: auto;
  p {
    color: #666666;
  }
  .title {
    margin-bottom: 10px;
    color: #009688;
  }
  .blocks {
    margin: 0 30px;
    .block {
      padding: 15px 0;
      border-bottom: 1px solid #cccccc;
      &:last-of-type {
        border-bottom: none;
      }
    }
    .block-title {
      margin-bottom: 10px;
      color: 333;
    }
    .block-body {
      margin: 0 15px 10px;
      display: flex;
      > label {
        margin-right: 15px;
        width: 160px;
        text-align: right;
        color: #606266;
      }
      > div {
        flex: 1;
        color: #333333;
      }
      &.danger {
        > label,
        > div {
          color: #f56c6c;
        }
      }
    }
  }
  .text-table {
    padding: 5px 0 0;
    font-size: 13px;
    > p {
      margin-bottom: 20px;
    }
    .el-table {
      margin: 0px 0 20px;
    }
  }
  .images {
    padding: 5px 0 0;
    .el-image {
      margin-right: 15px;
      width: 100px;
      height: 100px;
      border-radius: 5px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .image-null {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #c0c4cc;
      background: #f5f7fa;
    }
  }
  .videos {
    padding: 5px 0 0;
    video {
      margin-right: 15px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
</style>
