<template>
  <div class="content">
    <l-toggle>
      <dynamic-form :formItems="formItems" :model.sync="model"></dynamic-form>
      <search-reset :loading="loading" :model.sync="model" @search="
          _clearPagination();
          getList();
        "></search-reset>
    </l-toggle>
    <dynamic-table
      ref="dynamic-table"
      :loading="loading"
      :columns="columns"
      :tableData="tableData"
      :pagination.sync="pagination"
      @update="getList"
    ></dynamic-table>
    <acceptance ref="acceptance" @successCallback="getList"></acceptance>
  </div>
</template>

<script>
import LToggle from '../../../components/LToggle.vue';
import DynamicForm from '../../../components/DynamicForm.vue';
import SearchReset from '../../../components/SearchReset.vue';
import DynamicTable from '../../../components/DynamicTable.vue';
import baseMixin from '../../../mixins/base-mixin';
import Acceptance from './WaitGoodsAcceptance.vue';
import api from '../../../api/OrderManagement';

export default {
  name: 'TestValuationWaitGoods',
  components: {
    LToggle,
    DynamicForm,
    SearchReset,
    DynamicTable,
    Acceptance,
  },
  mixins: [baseMixin],
  data() {
    return {
      model: {},
      formItems: [
        {
          label: '订单编号',
          type: 'text',
          name: 'orderNumber',
          placeholder: '请输入订单编号',
        },
        {
          label: '客户姓名',
          type: 'text',
          name: 'userName',
          placeholder: '请输入客户姓名',
        },
        {
          label: '客户联系方式',
          type: 'text',
          name: 'userPhone',
          placeholder: '请输入客户联系方式',
        },
        {
          label: '订单开始时间',
          type: 'daterange',
          starName: 'orderStartTimeBegin',
          starPlaceholder: '请输入开始日期',
          endName: 'orderStartTimeEnd',
          endPlaceholder: '请输入结束日期',
        },
      ],
      columns: [
        {
          type: 'index',
          fixed: 'left',
        },
        {
          label: '订单编号',
          prop: 'orderNumber',
          minWidth: '100',
        },
        {
          label: '订单标题',
          prop: 'orderTitle',
          minWidth: '100',
        },
        {
          label: '客户姓名',
          prop: 'userName',
        },
        {
          label: '客户联系方式',
          prop: 'userPhone',
        },
        {
          label: '订单开始时间',
          prop: 'orderStartTime',
        },
        {
          label: '创建人',
          prop: 'creatorName',
        },
        {
          label: '快递寄出时间',
          prop: 'courierSentDate',
        },
        {
          label: '估值金额',
          prop: 'newestAssessmentPrice',
          align: 'right',
        },
        {
          label: '支付金额',
          prop: 'paymentAmount',
        },
        {
          label: '快递单号',
          prop: 'courierNumber',
        },
        {
          label: '操作',
          prop: '_action',
          fixed: 'right',
          actions: [
            {
              title: '查看',
              type: 'text',
              icon: 'el-icon-view',
              id: 'see',
            },
            {
              title: '受理',
              type: 'text',
              icon: 'el-icon-s-flag',
              event: this.acceptance,
            },
          ],
        },
      ],
      tableData: [],
      loading: false,
      pagination: {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      },
    };
  },
  computed: {},
  created() {},
  methods: {
    getList() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, pagination } = this;
        const { currentPage, pageSize } = pagination;
        const ext = {
          pageNum: currentPage,
          pageSize,
          ...model,
        };
        api
          .getReceivingOrderList(ext)
          .then(res => {
            const { datas, totalRecords } = res.result;
            this.tableData = datas;
            this.pagination.total = totalRecords;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    acceptance({ id }) {
      this.$refs.acceptance.open(id);
    },
  },
};
</script>

<style lang="scss"></style>
