<template>
  <el-drawer
    custom-class="drawer-submit-form"
    title="已检测待确认估值确认结束"
    :visible.sync="visible"
    size="100%"
    @close="close"
    @closed="closed"
  >
    <div class="submit-form">
      <dynamic-order-info
        :blocks="blocks"
        v-loading="loading"
      ></dynamic-order-info>
      <el-form
        ref="form"
        size="small"
        label-width="170px"
        :model="model"
        :rules="rules"
      >
        <div class="form-block">
          <p class="form-block-title">估值确认受理</p>
          <el-row>
            <el-col :span="10">
              <el-form-item label="估值金额" prop="assessmentPrice">
                <el-input
                  v-model="model.assessmentPrice"
                  placeholder="请输入估值金额"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="寄存天数" prop="consignDays">
                <el-input-number v-model="model.consignDays" :min="1" :max="30" label="请输入寄存天数"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="2" :offset="1">
              <el-button
                type="primary"
                size="mini"
                :loading="computeLoading"
                @click="compute"
              >
                计算
              </el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item prop="paymentAmount">
                <label slot="label" class="red">支付金额</label>
                <el-input v-model="model.paymentAmount"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item prop="sysConsignFee">
                <label slot="label" class="red">系统寄存手续费</label>
                <el-input v-model="model.sysConsignFee" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item prop="discountConsignFee">
                <label slot="label" class="red">折扣手续费</label>
                <el-input
                  v-model="model.discountConsignFee"
                  @blur="change('discountConsignFee')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item prop="consignFee">
                <label slot="label" class="red">最终报价手续费</label>
                <el-input v-model="model.consignFee" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="客户是否接受最新估值"
                prop="isAcceptAssessment"
              >
                <el-radio-group
                  v-model="model.isAcceptAssessment"
                  @change="value => change('isAcceptAssessment', value)"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="model.isAcceptAssessment === 0">
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="客户不接受最新报价原因"
                  prop="refuseReason"
                >
                  <el-select
                    v-model="model.refuseReason"
                    placeholder="请选择客户不接受最新报价原因"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="(option, optionIndex) in this.filterDictionary(
                        '0116',
                      )"
                      :key="`color-${optionIndex}`"
                      :label="option.name"
                      :value="option.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="客户不接受备注" prop="refuseRemark">
                  <el-input
                    type="textarea"
                    v-model="model.refuseRemark"
                    :rows="4"
                    placeholder="请输入备注内容"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="请上传与客户远程沟通确认估值结果截图"
                prop="attachment-7"
              >
                <el-input v-show="false" v-model="model['attachment-7']">
                </el-input>
                <l-upload-img
                  v-if="visible"
                  :fileUrl="mixinInitAttachment[7]"
                  :folderName="'order'"
                  @getFile="files => _updateFilefield(7, files)"
                ></l-upload-img>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="submit-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submit(0)">
        保存
      </el-button>
      <el-button type="warning" :loading="submitLoading" @click="submit(1)">
        提交
      </el-button>
    </div>
  </el-drawer>
</template>
<script>
import LUploadImg from '@/components/LUploadImg.vue';
import DynamicOrderInfo from '../../../components/DynamicOrderInfo.vue';
import { mapState } from 'vuex';
import api from '../../../api/OrderManagement';
import updateFilefieldMixin from '../../../mixins/update-filefield-mixin';
import getInfoMixin from '../../../mixins/get-info-mixin';
import valuationApi from '../../../api/ValuationAttrApi';

export default {
  name: 'ConfirmValuationEnd',
  components: {
    LUploadImg,
    DynamicOrderInfo,
  },
  props: {},
  mixins: [getInfoMixin, updateFilefieldMixin],
  data() {
    return {
      visible: false,
      id: '',
      loading: false,
      info: null,
      model: {
        assessmentPrice: '',
        consignDays: '',
        paymentAmount: '',
        sysConsignFee: '',
        discountConsignFee: '',
        consignFee: '',
        isAcceptAssessment: '',
        refuseReason: '',
        refuseRemark: '',
        'attachment-7': '',
      },
      rules: {
        assessmentPrice: [
          {
            required: true,
            message: '请输入',
          },
        ],
        consignDays: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        sysConsignFee: [
          {
            required: true,
            message: '请计算',
            trigger: 'change',
          },
        ],
        discountConsignFee: [
          {
            required: true,
            message: '请输入',
          },
        ],
        isAcceptAssessment: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        refuseReason: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        refuseRemark: [
          {
            required: true,
            message: '请输入',
          },
        ],
        'attachment-7': [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      mapOfAttachment: {},
      submitLoading: false,
      computeLoading: false,
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    blocks() {
      const orderAssessmentInfo = this.info?.orderAssessmentInfo;
      let result = [];
      if (orderAssessmentInfo) {
        result = [
          {
            list: [
              {
                lable: '系统分配订单ID',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderNumber,
              },
              {
                lable: '订单开始时间',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.orderStartTime,
              },
            ],
          },
          {
            title: '客户信息',
            list: [
              {
                lable: '客户姓名',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userName,
              },
              {
                lable: '客户手机',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userPhone,
              },
              {
                lable: '客户身份证号码',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.userIdentNo,
              },
            ],
          },
          {
            title: '产品信息',
            list: [
              {
                lable: '产品机型',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productName,
              },
              {
                lable: '产品信息',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productInfo,
              },
              {
                lable: '基础估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.basicPrice,
              },
              {
                lable: '产品序列号',
                type: 'text',
                value: orderAssessmentInfo?.baseOrderInfo?.productSerialNumber,
              },
              {
                lable: '初始估值',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.initAssessment,
              },
              {
                lable: '远程与客户报价',
                type: 'amount',
                value: orderAssessmentInfo?.baseOrderInfo?.priceQuotation,
              },
            ],
          },
          {
            title: '检测记录',
            list: [
              {
                lable: '系统最新估值',
                type: 'amount',
                value: orderAssessmentInfo?.orderDetectionInfo?.assessmentPrice,
              },
              {
                lable: '检测师估值',
                type: 'amount',
                value:
                  orderAssessmentInfo?.orderDetectionInfo?.detectionAssessment,
              },
              {
                lable: '检测师描述检测过程及估值判断依据',
                type: 'text',
                value: orderAssessmentInfo?.orderDetectionInfo?.detectionRemark,
              },
            ],
          },
        ];
      }
      return result;
    },
  },
  created() {},
  methods: {
    open(id) {
      this.id = id;
      this.loading = true;
      this.visible = true;
      this._getInfo();
      this._initSaveInfo('orderConfirmAssessment');
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    closed() {
      this.id = '';
      this.loading = false;
      this.submitLoading = false;
      this.info = null;
      this.mapOfAttachment = {};
      this.computeLoading = false;
    },

    submit(isSubmit) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi(isSubmit);
        }
      });
    },
    requestApi(isSubmit = 0) {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, model, mapOfAttachment } = this;

        const attachmentInfoList = Object.values(mapOfAttachment).flat();
        const ext = {
          isSubmit,
          orderId: id,
          ...model,
          attachmentInfoList,
          creatorId: this.userInfo.userId,
        };
        api
          .finishConfirmOrder(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
    compute() {
      const { assessmentPrice, consignDays } = this.model;
      if (!assessmentPrice) {
        this.$message.warning('请输入估值金额');
        return false;
      }
      if (!consignDays) {
        this.$message.warning('请选择寄存天数');
        return false;
      }
      this.computeLoading = true;
      const promise = new Promise((resolve, reject) => {
        const ext = {
          goodsAssessment: assessmentPrice,
          goodsConsignDays: consignDays,
        };
        valuationApi
          .assessmentCalculator(ext)
          .then(res => {
            this.$message.success('计算成功');
            const { consignFee, consignPrice } = res.result;
            const target = { ...this.model };
            Object.assign(target, {
              sysConsignFee: consignFee,
              paymentAmount: consignPrice,
            });
            this.model = target;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.computeLoading = false;
          });
      });
      return promise;
    },
    change(type) {
      switch (type) {
        case 'isAcceptAssessment':
          {
            const target = { ...this.model };
            Object.assign(target, {
              refuseReason: '',
              refuseRemark: '',
            });
            this.model = target;
          }

          break;
        case 'discountConsignFee':
          {
            const target = { ...this.model };
            const { sysConsignFee, discountConsignFee } = target;
            Object.assign(target, {
              consignFee: sysConsignFee - discountConsignFee,
            });
            this.model = target;
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
